

// src/utils/validators.ts

import { IMAGE_BASE_URL, IMAGE_ENDPOINT } from "../api/endpoints";
import { boxCompute, get1000Items } from "../features/boxSlice";
import { Box, BoxItem } from "../modal/box";
import { BoxComputeType, ComputeItem, ComputeItemType } from "../modal/Compute_Item";
import { Item } from "../modal/Item";
import { Row } from "../modal/row";

/**
 * Converts a string to a number and returns -1 if conversion fails or the string is not a valid number.
 * @param str - The string to convert.
 * @returns The converted number or -1 if the conversion is unsuccessful.
 */
export function convertStringToNumber(str: string): number {
    // Try to convert the string to a number
    const num = Number(str);
    
    // Check if the conversion was successful and the string contains only digits
    if (!isNaN(num) && /^\d+(\.\d+)?$/.test(str)) {
        return num;
    } else {
        return -1;
    }
}

/**
 * Validates if the given string represents a valid price.
 * @param str - The string to validate.
 * @returns True if the string is a valid price greater than 0, otherwise false.
 */
export const validatePrice = (str: string): boolean => {
    // Regular expression to match valid prices like "1000" or "1000.00"
    const regex = /^\d+(\.\d{1,2})?$/;
  
    // First check if the string matches the regex
    if (regex.test(str)) {
        // Then check if the number is greater than 0
        const number = parseFloat(str);
        return number > 0;
    }
  
    return false;
}

/**
 * Validates if the given string represents a valid stock quantity.
 * @param str - The string to validate.
 * @returns True if the string is a valid stock quantity greater than 0, otherwise false.
 */
export const validateStock = (str: string): boolean => {
    // Regular expression to match valid quantities like "1000"
    const regex = /^\d+$/;
  
    // First check if the string matches the regex
    if (regex.test(str)) {
        // Then check if the number is greater than 0
        const number = parseFloat(str);
        return number > 0;
    }
  
    return false;
}


export const getImageSource = (pic: string | undefined): string => {
    if (pic && /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(pic)) {
      return `${IMAGE_BASE_URL}${IMAGE_ENDPOINT}${pic}`;
    }
    return "/assets/default-box.png";
  };
  
  // Other utility functions can be added here if needed
  
  
  export function formatNumber(value?: number): string {
    if (value === undefined || value === null) {
      return '0'; // or return any default value or placeholder
    }
    return value % 1 === 0
      ? value.toString()
      : value.toFixed(2);
  }


  export function getInitialFiveEmptyRows (): Row[] {
    let newRows = [];
    for (let i = 0; i < 5; i++) {
        newRows.push({
            type: "",
            winProbability: 0.0,
            price: 0,
            name: "",
            id: "",
        });
    }
   
    return newRows;
};


  export function getRowsFromSelectedBoxUsingItems(items: Item[], box: Box): Row[] {
    const rows: Row[] = [];
  
    box.items.forEach((boxItem: BoxItem) => {
      // Find the matching item by id
      const matchingItem = items.find(item => item.id === boxItem.item_id);
      
      if (matchingItem) {
        // Construct the Row object using both BoxItem and Item properties
        const row: Row = {
          id: matchingItem.id,
          winProbability: boxItem.win_probability,
          type: boxItem.type,
          price: matchingItem.price,
          name: matchingItem.name
        };
        
        rows.push(row);
      }
    });
  
    return rows;
  }
  

  export const constructBoxComputeType = (rows: Row[]): BoxComputeType => {
    // if (rows.length === 0) {
    //   throw new Error('Rows array cannot be empty');
    // }
  
    // Base item: First item in the rows array
    const baseItem: ComputeItemType = {
      name: rows[0].name,
      price: rows[0].price,
    };
  
    // Default item: Last item in the rows array
    const defaultItem: ComputeItemType = {
      name: rows[rows.length - 1].name,
      price: rows[rows.length - 1].price,
    };
  
    // Normal items: All items between the first and last
    const normalItems: ComputeItemType[] = rows.slice(1, -1).map((row) => ({
      name: row.name,
      price: row.price,
    }));
  
    // Win probability of the base item (first item)
    const winProbabilityOfBaseItem: number = rows[0].winProbability;
  
    // Construct and return the BoxComputeType object
    return {
      base_item: baseItem,
      default_item: defaultItem,
      other_items: normalItems,
      win_probability_of_base_item: winProbabilityOfBaseItem,
    };
  };
  

  export const updateRowsWithComputedItem = (updatedRows: Row[], computedItem: ComputeItem): Row[] => {
    if (updatedRows.length === 0) {
      throw new Error('Rows array cannot be empty');
    }
  
    // Update the base item (first row)
    updatedRows[0].winProbability = computedItem.base_item.win_probability;
  
    // Update the default item (last row)
    updatedRows[updatedRows.length - 1].winProbability =
      computedItem.default_item.win_probability;
  
    // Update the normal items (rows between the base and default items)
    for (let i = 1; i < updatedRows.length - 1; i++) {
      updatedRows[i].winProbability = computedItem.other_items[i - 1].win_probability;
    }
  
    // Optionally log the updated rows for debugging
    console.log(JSON.stringify(updatedRows, null, 2));
  
    return updatedRows;
  };
  

 export const maxFileSize = 5 * 1024 * 1024;


 // Function to update rows based on index and item name using the provided items
// export const updateRowsWithItem = (index: number, itemName: string, items: Item[], rows: Row[]): Row[] => {
//     // Find the selected item by name
//     const selectedItemInner = items.find((item) => item.name === itemName);

//     // Create a copy of the rows to update
//     let updatedRows = [...rows];

//     // Update the specific row at the provided index with the selected item’s values
//     updatedRows[index] = {
//         winProbability: rows[index].winProbability, // Keep the current winProbability
//         price: selectedItemInner?.price ?? 0,
//         name: selectedItemInner?.name ?? "",
//         id: selectedItemInner?.id ?? "",
//         type: "other" // Temporarily set type to 'other'
//     };

//     // Get an array of all prices from the existing rows, excluding rows with price 0
//     const prices = updatedRows
//         .map((row) => row.price)
//         .filter((price) => price > 0);

//     // Find the maximum and minimum prices
//     const maxPrice = Math.max(...prices);
//     const minPrice = Math.min(...prices);

//     // Update all rows' 'type' attribute based on their prices
//     updatedRows = updatedRows.map((row) => {
//         const itemType = row.price === maxPrice
//             ? "base"          // If it's the highest price, set as "base"
//             : row.price === minPrice
//             ? "default"       // If it's the lowest price, set as "default"
//             : row.price > 0
//             ? "other"         // If the price is non-zero but not max/min, set as "other"
//             : "";             // If price is 0, leave type empty

//         return {
//             ...row,
//             type: itemType,
//         };
//     });

//     // Sort updatedRows by price in descending order (highest price first)
//     updatedRows = updatedRows.sort((a, b) => b.price - a.price);

//     return updatedRows;
// };


// Function to update rows based on index and item name using the provided items
export const updateRowsWithItem = (
    index: number,
    itemName: string,
    items: Item[],
    rows: Row[]
  ): Row[] => {
    // Find the selected item by name
    const selectedItemInner = items.find((item) => item.name === itemName);
  
    // Create a copy of the rows to update
    let updatedRows = [...rows];
  
    // Update the specific row at the provided index with the selected item’s values
    updatedRows[index] = {
      winProbability: rows[index].winProbability, // Keep the current winProbability
      price: selectedItemInner?.price ?? 0,
      name: selectedItemInner?.name ?? "",
      id: selectedItemInner?.id ?? "",
      type: "other", // Temporarily set type to 'other'
    };
  
    // Use the helper function to compute types and sort rows
    return updateRowTypesBasedOnPrices(updatedRows);
  };


  // Helper function to calculate max and min prices and update row types accordingly
// export const updateRowTypesBasedOnPrices = (rows: Row[]): Row[] => {
//     // Get an array of all prices from the existing rows, excluding rows with price 0
//     const prices = rows
//       .map((row) => row.price)
//       .filter((price) => price > 0);
  
//     // Find the maximum and minimum prices
//     const maxPrice = Math.max(...prices);
//     const minPrice = Math.min(...prices);
  
//     // Update all rows' 'type' attribute based on their prices
//     const updatedRows = rows.map((row) => {
//       const itemType =
//         row.price === maxPrice
//           ? "base" // If it's the highest price, set as "base"
//           : row.price === minPrice
//           ? "default" // If it's the lowest price, set as "default"
//           : row.price > 0
//           ? "other" // If the price is non-zero but not max/min, set as "other"
//           : ""; // If price is 0, leave type empty
  
//       return {
//         ...row,
//         type: itemType,
//       };
//     });
  
//     // Sort rows by price in descending order (highest price first)
//     return updatedRows.sort((a, b) => b.price - a.price);
//   };
  
// Helper function to calculate max and min prices and update row types accordingly
export const updateRowTypesBasedOnPrices = (rows: Row[]): Row[] => {
  // Get an array of all prices from the existing rows, excluding rows with price 0
  const prices = rows
    .map((row) => row.price)
    .filter((price) => price > 0);

  // Sort rows by price in descending order (highest price first)
  const sortedRows = rows.sort((a, b) => b.price - a.price);

  // Update row types based on index after sorting
  const updatedRows = sortedRows.map((row, index, array) => {
    let itemType = "";
    
    if (index === 0) {
      itemType = "base"; // First row (highest price)
    } else if (index === array.length - 1) {
      itemType = "default"; // Last row (lowest price)
    } else if (row.price > 0) {
      itemType = "other"; // Non-zero prices but not the first or last row
    }

    return {
      ...row,
      type: itemType,
    };
  });

  return updatedRows;
};

  

  export const isCaliberationRequiredForTheBox = async (box: Box, itemsArray: Item[]) => {
    const initiallyRows = getRowsFromSelectedBoxUsingItems(itemsArray, box);

    // Create a duplicate of initiallyRows
    const duplicateRows: Row[] = initiallyRows.map(row => ({ ...row }));

    let index = 0;
    let itemName: string = duplicateRows.length > 0 ? duplicateRows[0].name : "";
    let winProbability = duplicateRows.length > 0 ? duplicateRows[0].winProbability : 0;
    var rowsForCaliberation = updateRowsWithItem(index, itemName, itemsArray, duplicateRows);
    rowsForCaliberation[0].winProbability = winProbability;

    // Compute the new rows based on the calibration logic
    const computedItem = await boxCompute(rowsForCaliberation);
    console.log(JSON.stringify(rowsForCaliberation, null, 2));

    if (computedItem) {
        // Update rows with computedItem values
        const computedRowsFinally: Row[] = updateRowsWithComputedItem(
            [...rowsForCaliberation],
            computedItem
        );

        console.log("Computed Item:", computedItem);
        console.log(JSON.stringify(computedRowsFinally, null, 2));

        // Compare initiallyRows and computedRowsFinally for price and winProbability
        const hasChanges = initiallyRows.some((row, i) => {
            return (
                row.price !== computedRowsFinally[i].price ||
                row.winProbability !== computedRowsFinally[i].winProbability
            );
        });

        // Return true if there are changes, otherwise false
        return hasChanges;
    }

    return false;
};
