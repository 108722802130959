import React, { useState, useEffect, useRef, RefObject } from "react";
import { FaChevronDown } from "react-icons/fa";
import { RiUpload2Line } from "react-icons/ri";
import { ApiEndpoints, BASE_URL_NO_AAPI } from "../api/endpoints";
import { useDispatch, useSelector } from "react-redux";
import { FaTrash as TrashIcon } from 'react-icons/fa';
import { get1000Items, boxCompute, createBox, updateBox, resetCreateBoxStatus, resetUpdateBoxStatus, setSelectedBox, getBoxDetailsById } from "../features/boxSlice";
import { Buffer } from "buffer";
import noImageAvailable from "../assets/no_image.png";
import { toast, ToastContainer } from 'react-toastify';
import { convertStringToNumber, getInitialFiveEmptyRows, getRowsFromSelectedBoxUsingItems, isCaliberationRequiredForTheBox, maxFileSize, updateRowsWithComputedItem, updateRowsWithItem, updateRowTypesBasedOnPrices, validatePrice, validateStock } from "../utils/utils";
import { Box } from "../modal/box.js";
import { AppDispatch, RootState } from "../app/store.js";
import ErrorText from "../components/error_text";
import { Item } from "../modal/Item.js";
import MySelect from "../components/my_select";
import { api } from "../features/appSlice";
import { BoxContainer, Button, CalibrateButton, Checkbox, ChevronIcon, Container, FlexContainer, FlexRow, FlexRowRight, Form, ImagePreview, Input, Label, PriceContainer, RelativeDiv, Section, SelectContainer, SelectNormal, SelectWrapper, Table, Td, Textarea, Th } from '../styles/manage_box_page_styles'; // Import styled components
import BackButton from "../components/back_button";
import { useNavigate, useParams } from "react-router-dom";
import { GiAutoRepair } from "react-icons/gi";
import { Row } from "../modal/row";
import ItemSelect from "../components/item_select";
import { Spinner } from "../styles/GlobalStyles";



const AddUpdateBox: React.FC = () => {

    const { boxId } = useParams<{ boxId: string }>();

    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { createBoxStatus, updateBoxStatus, error, selectedBox, isCreatingOrUpdating } = useSelector(
        (state: RootState) => state.boxes
    );

    const { selectedItem } = useSelector(
        (state: RootState) => state.items
    );


    const [errorMessage, setErrorMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [name, setName] = useState(selectedBox?.name || "");
    const [tagsString, setTagsString] = useState("");
    const [level, setLevel] = useState(selectedBox?.level || 10);
    const [initialOrSuggestedPrice, setSuggestedPrice] = useState(selectedBox?.value || 0);
    const [price, setPrice] = useState(selectedBox?.value || 0);

    const [surcharge, setSurcharge] = useState(0);
    const [initialOrSuggestedSurcharge, setInitialOrSuggestedSurcharge] = useState(15);


    const [stock_count, setStockCount] = useState(selectedBox?.stock_count || 0);
    const [initial_stock_count] = useState(selectedBox?.stock_count || 0);

    const [description, setDescription] = useState(selectedBox?.description || "");
    const [isFeatured, setIsFeatured] = useState(
        selectedBox?.is_featured || false
    );

    const [boxType, setBoxType] = useState("Box");
    const [boxText, setBoxText] = useState("BOXCHAOS");
    const [boxTextColor, setBoxTextColor] = useState("#000000");
    const [boxTopImage, setBoxTopImage] = useState("");
    const [boxSideImage, setBoxSideImage] = useState("");
    const [fullBoxImage, setFullBoxImage] = useState("");
    const [previewToShow, setPreviewToShow] = useState(selectedBox?.picture ? BASE_URL_NO_AAPI + "/" + ApiEndpoints.FILE + selectedBox?.picture : null);
    const [isCustomImage, setIsCustomImage] = useState<boolean | null>(null);

    const topImageInputRef = useRef<HTMLInputElement>(null);
    const sideImageInputRef = useRef<HTMLInputElement>(null);
    const fullBoxImageInputRef = useRef<HTMLInputElement>(null);

    const [isValidName, setIsValidName] = useState(true);
    const [isValidTags, setIsValidTags] = useState(true);
    const [isValidPrice, setIsValidPrice] = useState(true);
    const [isValidDesc, setIsValidDesc] = useState(true);
    const [isValidStock, setIsValidStock] = useState(true);
    const [isValidSurcharge, setIsValidSurcharge] = useState(true);
    const [calibrationStatus, setCalibrationStatus] = useState<"loading" | "calibrationRequired" | "calibrationNotRequired">("loading");

    ///UI releated
    const [items, setItems] = useState<Item[]>([]);
    const [rows, setRows] = useState<Row[]>([]);
    const [inputRefs, setInputRefs] = useState<RefObject<HTMLInputElement>[]>([]);

    // Handle createBox status changes
    useEffect(() => {
        if (createBoxStatus === "succeeded") {
            toast("New Box Created!", {
                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                onClose: () => { },
            });

            dispatch(resetCreateBoxStatus());
        } else if (createBoxStatus === "failed") {
            toast(`Error creating box: ${error}`, {
                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                onClose: () => { },
            });

            dispatch(resetCreateBoxStatus());
        }
    }, [createBoxStatus, error, dispatch]);

    // Handle updateBox status changes
    useEffect(() => {
        if (updateBoxStatus === "succeeded") {
            toast("Updated Box!", {
                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                onClose: () => { },
            });

            dispatch(resetUpdateBoxStatus());
        } else if (updateBoxStatus === "failed") {
            toast(`Error updating box: ${error}`, {
                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                onClose: () => { },
            });

            dispatch(resetUpdateBoxStatus());
        }
    }, [updateBoxStatus, error, dispatch]);

    useEffect(() => {
        if (showToast && errorMessage) {
            // Show the toast
            toast.error(errorMessage);
            // Reset showToast to prevent repeated toasts
            setShowToast(false);
        }
    }, [showToast, errorMessage]);

    const triggerError = (message: string) => {
        setErrorMessage(message);
        setShowToast(true);
    };

    useEffect(() => {
        if (isGoingToCreateNewBox()) {
            addInitialFiveEmptyRows();
        }

        if (selectedBox) {
            fetchItems();
        } else {
            const idToUse = boxId;

            // Check if idToUse is a string before dispatching
            if (idToUse) {
                dispatch(getBoxDetailsById(idToUse)).unwrap()
                    .then(() => {
                        // if (getBoxDetailsByIdStatus === "succeeded") {
                        // } else if (getBoxDetailsByIdStatus === "failed") {
                        // }
                        fetchItems();
                    })
                    .catch((error: any) => {
                        // Handle error if needed
                        console.error("getBoxDetailsById  error:", error);
                    });;
            } else {
                fetchItems();
            }
        }

        // return () => {
        //     dispatch(setSelectedBox(null));
        // };
    }, [dispatch]);

    useEffect(() => {
        if (selectedBox?.tags) {
            // Since tags is already a string[], we can use it directly
            setTagsString(selectedBox.tags.join(", "));
            // setTags(selectedBox.tags);
        }
    }, [selectedBox?.tags]);

    const handleSelectChange = (event: { target: { value: string; }; }) => {
        const selectedValue = parseInt(event.target.value, 10);
        setLevel(selectedValue);
    };

    // Function to deselect all input fields
    const deselectAllFields = () => {
        inputRefs.forEach((ref) => {
            if (ref.current) {
                ref.current.blur();
            }
        });
    };



    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value)
        setIsValidName(!(value.trim().length === 0));
    };

    const handleTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTagsString(value)
        setIsValidTags(!(value.trim().length === 0));
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (value.trim().length === 0) {
            value = initialOrSuggestedPrice.toString();
        }

        setPrice(convertStringToNumber(value));
        setIsValidPrice(validatePrice(value));
    };


    const handleSurchargeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (value.trim().length === 0) {
            value = initialOrSuggestedSurcharge.toString();
        }

        setSurcharge(convertStringToNumber(value));
        setIsValidSurcharge(validatePrice(value));
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setDescription(value)
        setIsValidDesc(!(value.trim().length === 0));
    };

    const handleStockChange = (e: { target: { value: any; }; }) => {
        let value = e.target.value;
        if (value.trim().length === 0) {
            value = initial_stock_count;
        }
        setStockCount(value);
        setIsValidStock(validateStock(value));
    };


    const cancelAction = () => {
        navigate(-1);
    };

    const createOrUpdateAction = () => {
        const validPatternForWholeNumber = /^[1-9]\d*$/;
        const validPatternForPositiveNumber = /^\d+(\.\d+)?$/;
        const areAllRowsValid = rows.every((row) => row.price > 0);

        console.log("========================");
        console.log(rows);
        console.log("========================");

        const areProbabilitesValid = rows.every((row) => row.winProbability > 0);
        const isRowsCountValid = rows.length >= 3;

        // let priceAfterConversion = convertStringToNumber(priceString.toString());
        setIsValidName(!(name.trim().length === 0));
        setIsValidTags(!(tagsString.trim().length === 0));
        setIsValidPrice(validatePrice(price.toString()));
        setIsValidDesc(!(description.trim().length === 0));
        setIsValidStock(validateStock(stock_count.toString()));
        const baseRow = rows.find(r => rows.find(r => r.type === 'base'));

        if (name.trim() === "") return triggerError("Name is required!");
        else if (tagsString === "")
            return triggerError("Provoide some comma separated tags");
        else if (level === 0) return triggerError("Level is required!");
        else if (price === -1) return triggerError("Price is not valid!");
        else if (price === 0) return triggerError("Price is required!");
        else if (!validPatternForPositiveNumber.test(price.toString()))
            return triggerError("Price is not correct");
        else if (stock_count === 0)
            return triggerError("Boxes count is required!");
        else if (!validPatternForWholeNumber.test(stock_count.toString()))
            return triggerError(
                "Boxes count is not correct, please enter greater then zero"
            );
        else if (description === "")
            return triggerError("Description is required!");
        else if (rows.length === 0) {
            return triggerError("Items are required for box");
        } else if (!areAllRowsValid) {
            return triggerError(
                "Select priced items or remove empty choices before proceeding."
            );
        } else if (!areProbabilitesValid) {
            return triggerError(
                "All items should have a win probability of greater than zero."
            );
        } else if (!isRowsCountValid) {
            return triggerError("Box requires atleast 3 items");
        }
        else if (isCustomImage === null && selectedBox?.picture === null) {
            return triggerError("Box Image is required");
        }
        else if (baseRow && baseRow.winProbability >= 1) {
            return triggerError("Win probablity of Base item should be less then 1");
        }
        else if (surcharge === -1) return triggerError("surcharge is not valid!");
        else {
            setErrorMessage("");
            const transformedRows = rows.map((row) => ({
                item_id: row.id,
                win_probability: row.winProbability,
                type: row.type,
            }));


            const tagsArray = tagsString
                .split(",")
                .map((tag) => tag.trim())
                .filter((tag) => tag.length > 0);

            const requestJson: Box = {
                name: name as string,
                description: description as string,
                tags: tagsArray as string[],
                level: level as number,
                value: convertStringToNumber(price.toString()), // Ensuring `price` is properly converted
                stock_count: stock_count as number,
                items: transformedRows, // Adjust if needed for type consistency
                is_featured: isFeatured as boolean,
                surcharge: (surcharge > 0 ? surcharge : initialOrSuggestedSurcharge)
            };


            // Handle image customization logic
            if (isCustomImage !== null) {
                if (isCustomImage) {
                    // Assuming `boxPreviewQueryParams` returns an object of type PictureInput
                    requestJson.picture_inputs = boxPreviewQueryParams();
                } else {
                    requestJson.picture = fullBoxImage as string;
                }
            } else if (isCustomImage === null && selectedBox?.picture !== null) {
                requestJson.picture = selectedBox?.picture ?? ""; // Safely assign the picture
            }
            if (selectedBox !== null) {
                // Add id property to requestJson based on extraObject
                requestJson.id = selectedBox?.id;
                dispatch(updateBox(requestJson));
            } else {
                dispatch(createBox(requestJson));
            }
        }
    };

    const addInitialFiveEmptyRows = () => {
        let newRows = getInitialFiveEmptyRows();
        setRows([...rows, ...newRows]);
    };

    const addRowsFromSelectedBoxUsingItems = (itemsArray: any[]) => {
        const updatedTransformedItemsToRows = getRowsFromSelectedBoxUsingItems(itemsArray, selectedBox!);
        setRows(updatedTransformedItemsToRows);
    };

    const isGoingToCreateNewBox = () => {
        return selectedBox === null;
    };

    const fetchItems = () => {
        get1000Items()
            .then((itemsArray) => {
                setItems(itemsArray);
                if (!isGoingToCreateNewBox()) {
                    addRowsFromSelectedBoxUsingItems(itemsArray);
                    if (selectedBox) {
                        setCalibrationStatus('loading');
                        isCaliberationRequiredForTheBox(selectedBox, itemsArray).then((isRequired) => {
                            setCalibrationStatus(isRequired ? "calibrationRequired" : "calibrationNotRequired");
                        });
                    }
                }
            })
            .catch((error: any) => {
                console.error("Error fetching items:", error);
            });
    };

    const doesNameExist = (stringValue: string) => {
        return rows.some((row) => row.name === stringValue);
    };



    const handleItemSelect = (index: number, itemName: string | null) => {

        if (itemName == null) {
            return;
        }

        if (doesNameExist(itemName)) {
            toast("Item already in the box!", {
                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                onClose: () => { },
            });
            return;
        }

        var updatedRows = updateRowsWithItem(index, itemName, items, rows);

        // Update the state with the modified items and rows
        setRows(updatedRows);
        const delayInMilliseconds = 1000; // Set your desired delay in milliseconds
        setTimeout(() => {
            checkRowsAndCalculateProbabilities(0, updatedRows);
        }, delayInMilliseconds);
    };


    const performRowsCaliberation = () => {
        //get the select item name here then
        //find the index of item in the rows
        //if selected item is null then consider itemname from row[0] and index =0

        let index = 0;
        let itemName: string | null = rows.length > 0 ? rows[0].name : null;
        let winProbability = rows.length > 0 ? rows[0].winProbability : 0;

        if (itemName === null || itemName.trim().length === 0 || winProbability === 0) {
            return;
        }

        if (selectedItem) {
            //check if rows contain slectedItem.name then use it
            // also find index inside the rows and use it
            //otherwise use initialzed name and index.
            let checkIndex = rows.findIndex((row) => row.name === name);
            if (checkIndex !== -1) {
                index = checkIndex;
                itemName = selectedItem.name;
            }
        }

        if (itemName == null) {
            return;
        }

        var updatedRows = updateRowsWithItem(index, itemName, items, rows);
        updatedRows[0].winProbability = winProbability;

        // Update the state with the modified items and rows
        setRows(updatedRows);
        const delayInMilliseconds = 1000; // Set your desired delay in milliseconds
        setTimeout(() => {
            checkRowsAndCalculateProbabilities(0, updatedRows);
        }, delayInMilliseconds);
    };


    const createInputRef = () => {
        return React.createRef<HTMLInputElement>();
    };

    const addRow = (event: React.FormEvent) => {
        event.preventDefault();
        deselectAllFields();
        const delayInMilliseconds = 1000; // Set your desired delay in milliseconds

        // Use setTimeout to delay the addition of a new row
        setTimeout(() => {
            // Create and store the new input reference
            const newInputRef = createInputRef();
            setInputRefs((prevRefs) => [...prevRefs, newInputRef]);

            // Add the new row
            setRows((prevRows) => [
                ...prevRows,
                {
                    type: "",
                    winProbability: 0.0,
                    price: 0,
                    name: "",
                    id: "",
                },
            ]);
        }, delayInMilliseconds);
    };

    const removeRowAtIndex = (indexToRemove: number) => {
        deselectAllFields();
        const delayInMilliseconds = 1000; // Set your desired delay in milliseconds
        setTimeout(() => {
            // Create a copy of the rows array
            var updatedRows = [...rows];
            // Remove the item at the specified index
            updatedRows.splice(indexToRemove, 1);
            updatedRows = updateRowTypesBasedOnPrices(updatedRows);
            // Update the state with the new array
            setRows(updatedRows);
            const delayInMilliseconds = 1000; // Set your desired delay in milliseconds
            setTimeout(() => {
                checkRowsAndCalculateProbabilities(0, updatedRows);
            }, delayInMilliseconds);
        }, delayInMilliseconds);
    };

    const handleCompute = async (updatedRows: Row[], emptyRows: Row[]) => {
        try {
            // Construct BoxComputeType object
            console.log("=============================");
            console.log(JSON.stringify(updatedRows, null, 2));
            // Call the boxCompute function
            const computedItem = await boxCompute(updatedRows);
            console.log(JSON.stringify(updatedRows, null, 2));

            if (computedItem) {
                // Update rows with computedItem values
                const updatedRowsFinally: Row[] = updateRowsWithComputedItem(
                    [...updatedRows],
                    computedItem
                );

                // Set the state with the updated rows
                setRows([...updatedRowsFinally, ...emptyRows]);
                setPrice(computedItem.suggested_box_value);
                setSuggestedPrice(computedItem.suggested_box_value);
                setCalibrationStatus('calibrationNotRequired');
                // Do something with the computedItem
                console.log("Computed Item:", computedItem);
                console.log(JSON.stringify(updatedRowsFinally, null, 2));
            }

            // Print to console
            // console.log(JSON.stringify(computedItem, null, 2));
            // // Show alert
            // alert(JSON.stringify(computedItem, null, 2));
        } catch (error) {
            console.error("Error handling compute:", error);
        }
    };


    const caliberateRows = (event: React.FormEvent) => {
        event.preventDefault();
        performRowsCaliberation();
    };

    const checkRowsAndCalculateProbabilities = async (rowIndex: number, updatedRows: Row[]) => {
        // Ensure rowIndex is valid
        if (rowIndex < 0 || rowIndex >= updatedRows.length) {
            console.error("Invalid row index provided.");
            return;
        }

        // Filter rows with valid prices and those with zero price
        const validRows = updatedRows.filter(row => row.price > 0);
        const emptyRows = updatedRows.filter(row => row.price === 0);

        // Check if there are at least 3 valid rows
        if (validRows.length >= 3) {
            const baseRow = updatedRows[rowIndex];  // The row being checked

            // Validate base win probability and type, or first row
            const isBaseValid = baseRow.type === "base" && baseRow.winProbability > 0;
            const isFirstRowValid = updatedRows[0].type === "base" && updatedRows[0].winProbability > 0;

            if (isBaseValid || isFirstRowValid) {
                // Compute probabilities if base conditions are met
                await handleCompute(validRows, emptyRows);
            }
        }
    };

    const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>, rowIndex: number) => {
        const updatedRows = rows.map((row, index) => {
            if (index === rowIndex) {
                const winProbability =
                    e.target.value === "" ? row.winProbability : parseFloat(e.target.value);
                return { ...row, winProbability };
            }
            return row;
        });

        setRows(updatedRows);
        checkRowsAndCalculateProbabilities(rowIndex, updatedRows);
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, rowIndex: number) => {
        let inputValue = e.target.value;

        // Replace any non-numeric or non-decimal characters with an empty string
        inputValue = inputValue.replace(/[^\d.]+|(?<=\..*)\./g, "");

        // Update the input value
        e.target.value = inputValue;

        // Now, you can proceed with further validation or update your state
        const numericValue = inputValue === "" ? 0 : parseFloat(inputValue);

        // Check if the value is within the allowed range
        if (!isNaN(numericValue) && numericValue >= 0 && numericValue <= 100) {
            // If it's a valid decimal within the range, update the existing item
            const updatedRows = [...rows];
            updatedRows[rowIndex] = {
                ...updatedRows[rowIndex],
                winProbability: numericValue,
            };

            // Update the state with the modified rows
            setRows(updatedRows);
        } else {
            // If it's not within the allowed range, you can show an error message or take other actions
            // For example, display an error message to the user
            alert("Please enter a valid decimal number between 0 and 100.");
        }
    };


    function boxPreviewQueryParams() {
        return {
            template: boxType,
            text: boxText,
            text_color: boxTextColor,
            logo_on_side: boxSideImage,
            logo_on_lid: boxTopImage
        };
    }

    const getBoxPreview = async () => {
        try {
            // const queryParams = {
            //   template : boxType,
            //   text: boxText,
            //   text_color: boxTextColor,
            //   logo_on_side : boxSideImage ,
            //   logo_on_lid : boxTopImage
            // };
            //console.log(queryParams) ;
            const res = await api.get(ApiEndpoints.BOX_PREVIEW, {
                params: boxPreviewQueryParams(),
                responseType: 'arraybuffer',
            });

            if (res.status === 200) {
                console.log(res.data);
                //setBoxPeviewURL(new Uint8Array(res.data));
                let bImage = Buffer.from(res.data).toString('base64')
                //setBoxPeviewImage(bImage);
                setPreviewToShow(`data:image/png;base64,${bImage}`);
                setIsCustomImage(true);
                console.log(res.status);

            } else {
                console.log("fetching box preview => " + res.data);
            }
        } catch (e) {
            const errorMessage = (e as Error).message || (e as string).toString();
            console.log("Error while generating box preview " + errorMessage);

            toast("Error while generating box preview " + errorMessage, {
                // position: toast.POSITION.TOP_CENTER,
                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                onClose: () => { },
            });

            return triggerError("Error while generating box preview " + errorMessage);
        }
    };



    const uploadTopImage = async (event: React.ChangeEvent<HTMLInputElement>) => {


        const files = event.target.files;

        // Check if files is not null and has at least one file
        if (files && files.length > 0) {
            const file = files[0];
            // Handle file upload logic here
            console.log('File selected:', file);
            // For example, you might upload the file or process it here

            if (file) {
                if (file.type === "image/jpeg" || file.type === "image/png") {
                    try {

                        if (file.size <= maxFileSize) {
                            const formData = new FormData();
                            formData.append("file", file);
                            let res = await api.post(ApiEndpoints.FILE, formData, {
                                headers: { "Content-Type": "multipart/form-data" },
                            });
                            if (res.status === 200) {
                                setBoxTopImage(res.data.name);
                                console.log(res.data.name);
                            } else {
                                console.log("uploading top image status => " + res.status);
                            }
                        } else {
                            toast("File too large to be uploaded", {
                                //   position: toast.POSITION.TOP_CENTER,
                                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                                onClose: () => { },
                            });

                            return triggerError("Box image file too large to be uploaded");
                        }


                    } catch (e) {
                        const errorMessage = (e as Error).message || (e as string).toString();
                        console.log("Exception while uploading image => " + errorMessage);

                        if (errorMessage.includes("413")) {
                            toast("Box top image file too large to be uploaded", {
                                //   position: toast.POSITION.TOP_CENTER,
                                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                                onClose: () => { },
                            });

                            return triggerError("Box top image file too large to be uploaded");
                        } else {
                            toast("File can't be uploaded", {
                                //   position: toast.POSITION.TOP_CENTER,
                                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                                onClose: () => { },
                            });

                            return triggerError("File can't be uploaded");
                        }

                    }
                } else {
                    alert("Please select a valid image file (JPEG or PNG).");
                    event.target.value = ""; //null was here
                }
            }
        } else {
            // Handle the case where no file is selected or files is null
            console.log('No file selected or files is null');
        }



    };

    const uploadSideImage = async (event: React.ChangeEvent<HTMLInputElement>) => {


        const files = event.target.files;

        // Check if files is not null and has at least one file
        if (files && files.length > 0) {
            const file = files[0];

            if (file) {
                if (file.type === "image/jpeg" || file.type === "image/png") {
                    try {

                        if (file.size <= maxFileSize) {
                            const formData = new FormData();
                            formData.append("file", file);
                            let res = await api.post(ApiEndpoints.FILE, formData, {
                                headers: { "Content-Type": "multipart/form-data" },
                            });
                            if (res.status === 200) {
                                setBoxSideImage(res.data.name);
                                console.log(res.data.name);
                            } else {
                                console.log("uploading side image status => " + res.status);
                            }
                        } else {
                            return triggerError("Box image file too large to be uploaded");
                        }

                    } catch (e) {
                        const errorMessage = (e as Error).message || (e as string).toString();
                        console.log("Exception while uploading image => " + errorMessage);

                        if (errorMessage.includes("413")) {
                            return triggerError("Box side image file too large to be uploaded");
                        } else {
                            toast("File can't be uploaded", {
                                autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
                                onClose: () => { },
                            });

                            return triggerError("File can't be uploaded");
                        }
                    }
                } else {
                    alert("Please select a valid image file (JPEG or PNG).");
                    event.target.value = "";//null;
                }
            }
        }



    };

    const uploadFullBoxImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;

        // Check if files is not null and has at least one file
        if (files && files.length > 0) {
            const file = files[0];
            if (file) {
                if (file.type === "image/jpeg" || file.type === "image/png") {
                    try {

                        if (file.size <= maxFileSize) {
                            const formData = new FormData();
                            formData.append("file", file);
                            let res = await api.post(ApiEndpoints.FILE, formData, {
                                headers: { "Content-Type": "multipart/form-data" },
                            });
                            if (res.status === 200) {
                                setFullBoxImage(res.data.name);
                                setPreviewToShow(BASE_URL_NO_AAPI + "/" + ApiEndpoints.FILE + res.data.name);
                                setIsCustomImage(false);
                                console.log(res.data.name);
                            } else {
                                console.log("uploading complete image status => " + res.status);
                            }
                        } else {
                            return triggerError("Box image file too large to be uploaded");
                        }



                    } catch (e) {
                        const errorMessage = (e as Error).message || (e as string).toString();
                        console.log("Exception while uploading image => " + errorMessage);

                        if (errorMessage.includes("413")) {
                            return triggerError("Box image file too large to be uploaded");
                        } else {
                            return triggerError("File can't be uploaded");
                        }
                    }
                } else {
                    alert("Please select a valid image file (JPEG or PNG).");
                    event.target.value = ""; //null;
                }
            }

        }


    };

    interface OptionType {
        label: string;
        value: string;
    }


    return (
        <>
            <BackButton />
            <Container>
                {/* Upper section start */}
                <ToastContainer />
                <Section>
                    <FlexContainer>
                        <Label>Box Name</Label>
                        <Input
                            type="text"
                            placeholder="Name"
                            isValid={isValidName}
                            value={name}
                            onChange={handleNameChange}
                        />
                    </FlexContainer>
                    <FlexContainer>
                        <Label>Tag</Label>
                        <Input
                            type="text"
                            placeholder="Tag"
                            isValid={isValidTags}
                            value={tagsString}
                            onChange={handleTagsChange}
                        />
                    </FlexContainer>
                    <FlexContainer>
                        <Label>Feature Box</Label>
                        <Checkbox
                            type="checkbox"
                            checked={isFeatured}
                            onChange={() => setIsFeatured(!isFeatured)}
                        />
                    </FlexContainer>
                </Section>
                {/* Upper section end */}

                <Form>
                    <FlexContainer>
                        <p>Items in the box</p>
                        <CalibrateButton
                            onClick={caliberateRows}
                            title="Calibrate Items"
                            primary={calibrationStatus === "calibrationRequired"}
                        ><GiAutoRepair />
                        </CalibrateButton>
                        <Button onClick={addRow}>Add Row</Button>
                    </FlexContainer>
                    <Table>
                        <thead>
                            <tr>
                                <Th>Sr. #</Th>
                                <Th>Item Name</Th>
                                <Th>Price</Th>
                                <Th>Win Probability</Th>
                                <Th>Type</Th>
                                <Th></Th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.length === 0 ? <Label>Add Items...</Label> : ''}
                            {rows.map((item, index) => (
                                <tr key={index}>
                                    <Td>{index + 1}</Td>
                                    <Td>
                                        <SelectContainer>
                                            <ItemSelect
                                                items={items}
                                                item={item}
                                                index={index}
                                                handleItemSelect={handleItemSelect}
                                            />
                                        </SelectContainer>
                                    </Td>
                                    <Td>{item.price}</Td>
                                    <Td>
                                        <Input
                                            placeholder={item.winProbability.toString()}
                                            ref={inputRefs[index]}
                                            onChange={e => handleInputChange(e, index)}
                                            onBlur={e => handleBlur(e, index)}
                                            isValid={index === 0 ? (item.winProbability > 0 ? true : false) : true}
                                            disabled={index > 0}
                                        />
                                    </Td>
                                    <Td>{item.type}</Td>
                                    <Td>
                                        <Button
                                            onClick={e => {
                                                e.preventDefault();
                                                removeRowAtIndex(index);
                                            }}
                                        >
                                            <TrashIcon className="w-5" />
                                        </Button>
                                    </Td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Form>

                {/* Bottom section start */}
                <Section>
                    <PriceContainer>
                        <Label>Price</Label>
                        <div style={{ position: 'relative' }}>
                            <span style={{ position: 'absolute', left: '8px', color: '#6b7280' }}>$</span>
                            <Input
                                type="text"
                                placeholder={initialOrSuggestedPrice === 0 ? '00.00' : initialOrSuggestedPrice.toString()}
                                isValid={isValidPrice}
                                onChange={handlePriceChange}
                                style={{ paddingLeft: '24px' }}
                            />
                        </div>
                    </PriceContainer>
                    <PriceContainer>
                        <Label>Surcharge</Label>
                        <div style={{ position: 'relative' }}>
                            <Input
                                type="text"
                                placeholder={initialOrSuggestedSurcharge === 0 ? '00.00' : initialOrSuggestedSurcharge.toString()}
                                isValid={isValidSurcharge}
                                onChange={handleSurchargeChange}
                                style={{ paddingLeft: '24px' }}
                            />
                        </div>
                    </PriceContainer>
                    <PriceContainer>
                        <Label>Total price</Label>
                        <div style={{ position: 'relative' }}>
                            <span style={{ position: 'absolute', left: '8px', color: '#6b7280' }}>$</span>
                            <Input
                                type="text"
                                disabled={true}
                                placeholder={'00.00'}
                                isValid={true}
                                value={
                                    (surcharge > 0 ? surcharge : initialOrSuggestedSurcharge)/100.0 *
                                    (price > 0 ? price : initialOrSuggestedPrice) +
                                    (price > 0 ? price : initialOrSuggestedPrice)
                                }
                                style={{ paddingLeft: '24px' }}
                            />

                        </div>
                    </PriceContainer>
                    <FlexContainer>
                        <Label>Level</Label>
                        <RelativeDiv>
                            <MySelect level={level} handleSelectChange={handleSelectChange} />
                            <span style={{ position: 'absolute', right: 0, backgroundColor: '#9ca3af', height: '100%', width: '20px', pointerEvents: 'none' }}>
                                <FaChevronDown style={{ position: 'absolute', top: '8px', left: '4px', color: 'white', fontSize: '12px' }} />
                            </span>
                        </RelativeDiv>
                    </FlexContainer>

                    <FlexContainer style={{ flexDirection: 'column' }}>
                        <Label>Description</Label>
                        <Textarea
                            placeholder="Add text."
                            isValid={isValidDesc}
                            value={description}
                            onChange={handleDescriptionChange}
                            rows={6} // Start with 1 row, adjust as needed
                        />
                    </FlexContainer>

                    <FlexContainer>
                        <Label>Number of Boxes</Label>
                        <Input
                            type="text"
                            placeholder={initial_stock_count === 0 ? '0' : initial_stock_count.toString()}
                            isValid={isValidStock}
                            onChange={handleStockChange}
                        />
                    </FlexContainer>

                    {/* Add image / add custom image / preview image */}
                    <Label>Add Box image:</Label>
                    <BoxContainer>
                        <FlexRow>
                            <div>
                                <FlexRow>
                                    <Label>Box Template</Label>
                                    <SelectNormal value={boxType} onChange={(e) => setBoxType(e.target.value)}>
                                        <option value="Box">Box</option>
                                        <option value="Box_flipped">Box Flipped</option>
                                    </SelectNormal>
                                </FlexRow>
                                <FlexRow>
                                    <Label>Box Text</Label>
                                    <Input
                                        type="text"
                                        placeholder="Box Text"
                                        value={boxText}
                                        onChange={(e) => setBoxText(e.target.value)}
                                        isValid={boxText.length > 0}
                                    />
                                </FlexRow>
                                <FlexRow>
                                    <Label>Text Color</Label>
                                    <Input
                                        type="color"
                                        value={boxTextColor}
                                        onChange={(e) => setBoxTextColor(e.target.value)}
                                        isValid={true}
                                    />
                                </FlexRow>
                                <FlexRow>
                                    <Label>Top Image</Label>
                                    <Input
                                        type="text"
                                        placeholder="Image URL"
                                        value={boxTopImage}
                                        onChange={(e) => setBoxTopImage(e.target.value)}
                                        isValid={true}
                                    />
                                    <Button onClick={() => topImageInputRef?.current?.click()}>
                                        <RiUpload2Line size={20} />
                                    </Button>
                                    <input
                                        type="file"
                                        onChange={uploadTopImage}
                                        accept=".jpg, .jpeg, .png"
                                        ref={topImageInputRef}
                                        style={{ display: 'none' }}
                                    />
                                </FlexRow>
                                <FlexRow>
                                    <Label>Side Image</Label>
                                    <Input
                                        type="text"
                                        placeholder="Image URL"
                                        value={boxSideImage}
                                        onChange={(e) => setBoxSideImage(e.target.value)}
                                        isValid={true}
                                    />
                                    <Button onClick={() => sideImageInputRef?.current?.click()}>
                                        <RiUpload2Line size={20} />
                                    </Button>
                                    <input
                                        type="file"
                                        onChange={uploadSideImage}
                                        accept=".jpg, .jpeg, .png"
                                        ref={sideImageInputRef}
                                        style={{ display: 'none' }}
                                    />
                                </FlexRow>
                                <Label>Maximum file size: 5 MB</Label>
                                <FlexRow>
                                    <Button primary onClick={getBoxPreview}>
                                        Preview
                                    </Button>
                                </FlexRow>
                                <div>
                                    <strong>OR</strong>
                                    <FlexRow>
                                        <Label>Upload your own Box image</Label>
                                        <Button onClick={() => fullBoxImageInputRef?.current?.click()}>
                                            <RiUpload2Line size={20} />
                                        </Button>
                                        <input
                                            type="file"
                                            onChange={uploadFullBoxImage}
                                            accept=".jpg, .jpeg, .png"
                                            ref={fullBoxImageInputRef}
                                            style={{ display: 'none' }}
                                        />
                                    </FlexRow>
                                </div>
                            </div>

                            {true && (
                                <ImagePreview>
                                    <img
                                        src={previewToShow || noImageAvailable}
                                        alt="Selected Image"
                                    />
                                </ImagePreview>
                            )}
                        </FlexRow>
                    </BoxContainer>

                    <FlexRowRight>
                        <ErrorText>{errorMessage}</ErrorText>
                    </FlexRowRight>
                    <FlexRow>
                        <Button onClick={cancelAction}>Cancel</Button>
                        <Button
                            primary
                            onClick={createOrUpdateAction}
                            disabled={isCreatingOrUpdating}
                        >
                            {isCreatingOrUpdating && <Spinner />}
                            {isCreatingOrUpdating ? '' : (selectedBox === null ? 'Add Box' : 'Update Box')}
                        </Button>
                    </FlexRow>
                </Section>
                {/* Bottom section end */}
            </Container>
        </>
    );
}

export default AddUpdateBox;

