import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/login_page';
import DashboardPage from './pages/dashboard_page';

const AppRouter: React.FC = () => {
  // const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  // useEffect(() => {
  //   // Check for token and update authentication state
  //   const token = localStorage.getItem('token');
  //   setIsAuthenticated(!!token);
  // }, []);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/dashboard/*" element={localStorage.getItem('token')!=null ? <DashboardPage /> : <Navigate to="/login" />} />
        <Route path="/" element={localStorage.getItem('token')!=null ? <Navigate to="/dashboard/items" /> : <Navigate to="/login" />} />
      </Routes>
    </Router>
  );
};

export default AppRouter;
