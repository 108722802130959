
export class Item {
  id: string;
  name: string;
  description: string;
  picture: string;
  tags: string[];
  price: number;
  stockCount: number;

  constructor(id: string, name: string, description: string, picture: string, tags: string[], price: number, stockCount: number) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.picture = picture;
    this.tags = tags;
    this.price = price;
    this.stockCount = stockCount;
  }

  static fromApiResponse(apiResponse: any): Item {
    const { id, name, description, picture, tags, price, stock_count: stockCount } = apiResponse;
    return new Item(id, name, description, picture, tags, price, stockCount);
  }

  static parseItems(apiResponseArray: any[]): Item[] {
    if (Array.isArray(apiResponseArray)) {
      return apiResponseArray.map(itemData => Item.fromApiResponse(itemData));
    }
    return [];
  }

  toApiObject(): any {
    return {
      name: this.name,
      description: this.description,
      picture: this.picture,
      tags: this.tags,
      price: this.price,
      stock_count: this.stockCount,
    };
  }

  toJSON(): string {
    return JSON.stringify({
      id: this.id,
      name: this.name,
      description: this.description,
      picture: this.picture,
      tags: this.tags,
      price: this.price,
      stock_count: this.stockCount,
    });
  }

  static fromJSON(json: string): Item {
    const { id, name, description, picture, tags, price, stock_count: stockCount } = JSON.parse(json);
    return new Item(id, name, description, picture, tags, price, stockCount);
  }

  toString(): string {
    return `Item { id: ${this.id}, name: ${this.name}, description: ${this.description}, picture: ${this.picture}, tags: [${this.tags.join(', ')}], price: ${this.price}, stockCount: ${this.stockCount} }`;
  }
}
