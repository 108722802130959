import React from 'react';
import styled, { keyframes } from 'styled-components';

interface ErrorTextProps {
    styleClass?: string; // Optional string for additional class names
    children: React.ReactNode; // Content to be displayed inside the component
}

// Define a keyframe animation for the splashing effect
const splashAnimation = keyframes`
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
`;

const StyledErrorText = styled.p`
    color: red;
    font-weight: bold;
    text-align: center;
    animation: ${splashAnimation} 0.6s ease-in-out infinite;
`;

const ErrorText: React.FC<ErrorTextProps> = ({ styleClass = '', children }) => {
    return (
        <StyledErrorText className={styleClass}>
            {children}
        </StyledErrorText>
    );
}

export default ErrorText;
