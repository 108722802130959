import { css, keyframes, styled } from "styled-components";
import { FaChevronDown } from "react-icons/fa";

// Define styled components
export const Container = styled.div`
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 16px;
  color: #6b7280;
`;

export const Section = styled.section`
  margin-bottom: 16px;
`;

export const Form = styled.form`
  border: 1px solid #d1d5db;
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const Label = styled.label`
  margin-right: 8px;
`;

export const Input = styled.input<{ isValid?: boolean }>`
  width: 300px;
  padding: 4px;
  border: 1px solid ${(props) => (props.isValid ? "#d1d5db" : "#f87171")};
  outline: none;
  z-index: 10;
`;

export const Checkbox = styled.input`
  /* Add any specific styles if required */
`;

// const Button = styled.button`
// background-color: #f97316;
// color: white;
// font-size: 0.875rem;
// padding: 4px 8px;
// border-radius: 4px;
// `;

// Define the type for the Button component's props
export interface ButtonProps {
  primary?: boolean; // Optional boolean prop for primary styling
}

export const Button = styled.button<ButtonProps & { disabled?: boolean }>`
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 500;
  transition: background-color 0.3s ease, transform 0.2s ease;

  ${({ primary }) =>
    primary
      ? `
        background-color: #4CAF50;
        color: white;
        border: 1px solid #4CAF50;
        
        &:hover {
          background-color: #45a049;
          transform: scale(1.05);
        }
    `
      : `
        background-color: #e2e8f0;
        color: #2d3748;
        border: 1px solid #cbd5e0;
        
        &:hover {
          background-color: #cbd5e0;
          transform: scale(1.05);
        }
    `}

  &::after {
    content: '';
    display: ${({ disabled }) => (disabled ? 'block' : 'none')};
    position: absolute;
    border: 2px solid transparent;
    border-radius: 50%;
    border-top: 2px solid #fff;
    width: 1rem;
    height: 1rem;
    animation: spin 1s linear infinite;
  }

  position: relative;
  overflow: hidden;
`;


// Define keyframes for blinking effect
const blink = keyframes`
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
`;

export interface CalibrateButtonProps {
  primary?: boolean; // Optional boolean prop for primary styling
  title?: string; // Optional title prop for displaying the button title
}

export const CalibrateButton = styled.button<CalibrateButtonProps>`
  padding: 0.4rem 0.8rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.9rem;
  font-weight: 500;

  transition: background-color 0.3s ease, transform 0.2s ease;

  ${({ primary, title }) =>
    primary
      ? css`
        background-color: #e63946; /* Red color for calibration required */
        color: white;
        border: 1px solid #e63946;

        &:hover {
          background-color: #d62839; /* Darker red on hover */
          transform: scale(1.05);
        }

        /* Blinking effect for the title */
        &::after {
          content: "${title || 'Calibration Required'}";
          display: block;
          color: white;
          font-size: 0.8rem;
          animation: ${blink} 1s infinite;
        }
    `
      : css`
        background-color: #e0e7eb; /* Light Steel Grey */
        color: #334e68; /* Dark Steel Grey */
        border: 1px solid #cbd5e0;

        &:hover {
          background-color: #cfd8dc; /* Darker Grey on hover */
          transform: scale(1.05);
        }

        /* Static title */
        &::after {
          content: "${title || 'Calibrate Items'}";
          display: block;
          color: #334e68; /* Dark Steel Grey */
          font-size: 0.8rem;
        }
    `}

  svg {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }
`;

export const Table = styled.table`
  width: 100%;
  text-align: center;
  border-collapse: collapse;
`;

export const Th = styled.th`
  background-color: rgba(156, 163, 175, 0.5);
  padding: 8px;
`;

export const Td = styled.td`
  padding: 8px;
  border: 1px solid #d1d5db;
`;

export const RelativeDiv = styled.div`
  position: relative;
  border: 1px solid #d1d5db;
`;

export const PriceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  position: relative;
`;

export const Textarea = styled.textarea<{ isValid?: boolean }>`
  width: 100%;
  padding: 4px;
  border: 1px solid ${(props) => (props.isValid ? "#d1d5db" : "#f87171")};
  outline: none;
`;

export const ImagePreviewContainer = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
`;

export const SelectWrapper = styled.div`
  width: 100%;
  min-width: 130px;
`;

export const SelectContainer = styled.div`
  position: relative;
`;

export const ChevronIcon = styled(FaChevronDown)`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const ColorInput = styled.input`
  width: 50px;
  height: 30px;
  padding: 0;
`;

export const BoxContainer = styled.div`
  border: 1px solid #ddd;
  padding: 16px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

export const FlexRowRight = styled.div`
  display: flex;
  justify-content: right;
  margin-bottom: 8px;
`;

export const SelectNormal = styled.select`
  width: 300px;
  padding: 4px;
  border: 1px solid #ddd;
  margin-left: 40px;
`;

export const ImagePreview = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

