// src/features/pageSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PageState {
  selectedPage: string;
}

const initialState: PageState = {
  selectedPage: 'boxes', // Default selected page
};

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    selectPage: (state, action: PayloadAction<string>) => {
      state.selectedPage = action.payload;
    },
  },
});

export const { selectPage } = pageSlice.actions;
export default pageSlice.reducer;
