import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../app/store";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { formatNumber, getImageSource, isCaliberationRequiredForTheBox } from "../utils/utils"; // Ensure this is properly imported
import { getBoxesForSelectedItem } from "../features/itemsSlice";
import { Box } from "../modal/box";
import { get1000Items, setSelectedBox } from "../features/boxSlice";
import { Item } from "../modal/Item";

export const SquareGrid = styled.div<{ columns: number }>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, 1fr);
  gap: 16px;
  margin-top: 16px;
`;

export const SquareBox = styled.div`
  width: 100%;
  padding-top: 121.4%;
  position: relative;
  border-radius: 16px;
  font-family: SonsieOne;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  background-image: url("/assets/Box_Container_BG.png"); /* Add your image path here */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: visible; /* Ensure content doesn't affect the box size */
`;

export const BoxContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Adjust padding as needed */
  box-sizing: border-box;
`;

export const BoxTitle = styled.h3`
  font-family: Verdana;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  position: absolute;
  bottom: 3.7%;
  left: 7.1%;
  right: 7.1%;
  margin: 0; /* Remove default margin */
`;

export const PriceTag = styled.div`
  width: auto;
  height: 7.3%;
  margin: 20px 8px 5px 0;
  padding: 2px 10px 1px;
  background-color: #80d5ff;
  position: absolute;
  bottom: 11.8%;
  left: 7.1%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  display: flex;
  align-items: center; /* Vertical centering */
  font-family: Verdana;
  font-size: clamp(10px, 3vw, 14px);
  font-weight: bold;
  color: #f00;
`;

export const BoxImage = styled.img<{ $isHovered: boolean }>`
  width: 71.4%;
  object-fit: cover;
  position: absolute;
  top: ${(props) => (props.$isHovered ? "8%" : "14%")};
  left: 50%;
  transform: translateX(-50%);
  transition: top 0.3s ease-in-out; /* Add smooth transition effect */
`;

const CalibrationIndicator = styled.div`
  position: absolute;
  top: 5%;
  right: 5%;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
`;

const NoRecordsFoundMessage = styled.p`
  padding: 50px;
  text-align: center;
  color: #40a5c5;
`;

// Custom spinner styled-component
const Spinner = styled.div`
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-top: 3px solid white;
  border-radius: 50%;
  width: 14px;
  height: 14px;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

interface DynamicBoxesForAllProps {
    itemId: string;
    columns: number;
    refreshKey: number; // Add refreshKey prop
  }
  
  export const DynamicBoxesForAll: React.FC<DynamicBoxesForAllProps> = ({
    itemId,
    columns,
    refreshKey,  // Destructure refreshKey
  }) => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
  
    const boxes = useSelector((state: RootState) => state.items.boxesForSelectedItem ?? []);
    const [hoveredBox, setHoveredBox] = useState<string | null>(null);
    const [calibrationStatus, setCalibrationStatus] = useState<{
      [key: string]: "loading" | "calibrationRequired" | "calibrationNotRequired";
    }>({});
    const [itemsArray, setItemsArray] = useState<Item[]>([]);
  
    const isFetching = useSelector((state: RootState) => state.items.isLoading);
    const totalBoxes = useSelector((state: RootState) => state.items.boxesForSelectedItem.length);
  
    // Fetch boxes when itemId or refreshKey changes
    useEffect(() => {
      dispatch(getBoxesForSelectedItem(itemId));
    }, [dispatch, itemId, refreshKey]);  // Include refreshKey here to refetch data
  
    useEffect(() => {
      const fetchItems = async () => {
        const items = await get1000Items(); // Fetch items once
        setItemsArray(items);
      };
  
      fetchItems();
    }, [refreshKey]);  // Refetch items when refreshKey changes
  
    useEffect(() => {
      // Check calibration status for each box when boxes and items are fetched
      if (boxes.length && itemsArray.length) {
        boxes.forEach((box) => {
          setCalibrationStatus((prevState) => ({
            ...prevState,
            [box.id!]: "loading",
          }));
  
          isCaliberationRequiredForTheBox(box, itemsArray).then((isRequired) => {
            setCalibrationStatus((prevState) => ({
              ...prevState,
              [box.id!]: isRequired ? "calibrationRequired" : "calibrationNotRequired",
            }));
          });
        });
      }
    }, [boxes, itemsArray, refreshKey]);  // Also trigger recalibration check when refreshKey changes
  
    const handleBoxClick = (box: Box) => {
      dispatch(setSelectedBox(box));
      navigate(`/dashboard/boxes/details/${box.id}`);
    };
  
    return (
      <>
        {totalBoxes === 0 ? (
          <NoRecordsFoundMessage>
            {isFetching ? "Loading ..." : "No box contains the current item."}
          </NoRecordsFoundMessage>
        ) : (
          <SquareGrid columns={columns}>
            {boxes.map((box, index) => {
              const calibrationState = calibrationStatus[box.id!] || "loading";
  
              return (
                <SquareBox
                  key={index}
                  onMouseEnter={() => setHoveredBox(box.id!)}
                  onMouseLeave={() => setHoveredBox(null)}
                  onClick={() => handleBoxClick(box)}
                >
                  <BoxContent>
                    <BoxImage
                      src={getImageSource(box.picture)}
                      $isHovered={hoveredBox === box.id}
                    />
                    <BoxTitle>{box.name}</BoxTitle>
                    <PriceTag>${formatNumber(box.value)}</PriceTag>
  
                    {calibrationState === "loading" ? (
                      <CalibrationIndicator>
                        <Spinner />
                      </CalibrationIndicator>
                    ) : calibrationState === "calibrationRequired" ? (
                      <CalibrationIndicator>!</CalibrationIndicator>
                    ) : null}
                  </BoxContent>
                </SquareBox>
              );
            })}
          </SquareGrid>
        )}
      </>
    );
  };
  