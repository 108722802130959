import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Subtitle from './sub_title';

// Define the types for the component props
interface TitleCardProps {
  title: string;
  children: ReactNode;
  topMargin?: string;
  TopSideButtons?: ReactNode;
}

// Styled components
const CardContainer = styled.div<{ topMargin?: string }>`
  width: 100%;
  padding: 1.5rem;
  background-color: var(--color-base-100); // Replace with your theme color variable
  box-shadow: var(--shadow-xl); // Replace with your theme shadow variable
  margin-top: ${(props) => props.topMargin || '1.5rem'};
`;

const Divider = styled.div`
  margin-top: 0.5rem;
  border-bottom: 1px solid var(--color-divider); // Replace with your theme color variable
`;

const CardBody = styled.div`
  height: 100%;
  width: 100%;
  padding-bottom: 1.5rem;
  background-color: var(--color-base-100); // Replace with your theme color variable
`;

const InlineBlock = styled.div`
  display: inline-block;
  float: right;
`;

// The TitleCard component using TypeScript and styled-components
const TitleCard: React.FC<TitleCardProps> = ({ title, children, topMargin, TopSideButtons }) => {
  return (
    <CardContainer topMargin={topMargin}>
      {/* Title for Card */}
      <Subtitle styleClass={TopSideButtons ? 'inline-block' : ''}>
        {title}

        {/* Top side button, show only if present */}
        {TopSideButtons && <InlineBlock>{TopSideButtons}</InlineBlock>}
      </Subtitle>

      <Divider />

      {/* Card Body */}
      <CardBody>
        {children}
      </CardBody>
    </CardContainer>
  );
};

export default TitleCard;
