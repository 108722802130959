import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBanners, resetDeleteBannerStatus, setSelectedBanner, updateBannerOrder, updateBannerOrderOnServer } from '../features/bannersSlice'; // Adjust according to your slice's path
import { AppDispatch, RootState } from '../app/store'; // Adjust according to your store's path
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import useConfirmation from '../components/confirm_modal';
import SortableRow from '../components/sortable_row';

const BannersPage: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { confirm, ConfirmationModal } = useConfirmation();

  // Access banners, loading, and error state from Redux
  const { banners, fetchBannersStatus, fetchBannersError, deleteBannerError, deleteBannerStatus } = useSelector((state: RootState) => state.banners);

  // Load banners when the component mounts
  useEffect(() => {
    dispatch(fetchBanners('all')); // Fetch all banners on component mount
  }, [dispatch]);

  useEffect(() => {
    if (deleteBannerStatus === "succeeded") {
      toast("Item deleted", {
        autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
        onClose: () => { },
      });

      dispatch(resetDeleteBannerStatus());
    } else if (deleteBannerStatus === "failed") {
      toast(`Error deleting Item: ${deleteBannerError}`, {
        autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
        onClose: () => { },
      });

      dispatch(resetDeleteBannerStatus());
    }
  }, [deleteBannerStatus, deleteBannerError, dispatch]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      // Update the order of banners
      const oldIndex = banners.findIndex((banner) => banner.id === active.id);
      const newIndex = banners.findIndex((banner) => banner.id === over.id);

      if (oldIndex !== -1 && newIndex !== -1) {
        // Update state with the reordered banners
        const reorderedBanners = arrayMove(banners, oldIndex, newIndex);
        // Here you might want to dispatch an action to update the order on the server
        // Dispatch action to update the local state
        dispatch(updateBannerOrder(reorderedBanners));

        // Dispatch action to update the order on the server
        dispatch(updateBannerOrderOnServer(reorderedBanners));
      }
    }
  };

  const handleAddNewIBoxClick = () => {
    dispatch(setSelectedBanner(null));
    navigate(`/dashboard/banners/details`);
  };


  // Render content based on loading state
  if (fetchBannersStatus === 'loading') {
    return <div>Loading banners...</div>;
  }

  if (fetchBannersStatus === 'failed') {
    return <div>Error: {fetchBannersError}</div>;
  }

  return (
    <Container>
      <ToastContainer />
      <ConfirmationModal />

      <Header>
        <Title>Banners</Title>
        <NewBannerButton onClick={handleAddNewIBoxClick}>+ New Banner</NewBannerButton>
      </Header>

      {fetchBannersStatus === 'succeeded' && banners.length === 0 ? (
        <div>No banners available</div> // Display placeholder when no banners
      ) : (
        <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={banners} strategy={verticalListSortingStrategy}>
            <Table>
              <thead>
                <tr>
                  <Th></Th>
                  <Th>Image</Th>
                  <Th>View Order</Th>
                  <Th>Active</Th>
                  <Th>URL</Th>
                  <Th>Actions</Th>
                </tr>
              </thead>
              <tbody>
                {banners.map((banner, index) => (
                  <SortableRow key={banner.id} banner={banner} index={index} confirm={confirm} />
                ))}
              </tbody>
            </Table>
          </SortableContext>
        </DndContext>
      )}
    </Container>
  );
};

export default BannersPage;

// Styled components
// [Same as the ones you had before...]


// Styled components
const Container = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 24px;
  color: #333;
`;

const NewBannerButton = styled.button`
  background-color: #28a745;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: #218838;
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 8px;
  overflow: hidden;
`;

const Th = styled.th`
  padding: 12px 15px;
  background-color: #f1f1f1;
  text-align: left;
  color: #333;
`;

