import React from 'react';

interface Props {
    level: number;
    handleSelectChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

const MySelect: React.FC<Props> = ({ level, handleSelectChange }) => {
    // Convert the iterable to an array before mapping
    const options = Array.from({ length: 10 }, (_, i) => i + 1);

    return (
        <select
            className="border w-[300px] px-1 focus:outline-none"
            value={level} // Set the selected value to the current state value
            onChange={handleSelectChange} // Add an onChange event handler
        >
            <option value="" disabled>Select a level</option> {/* Placeholder option */}
            {options.map((value) => (
                <option key={value} value={value}>
                    {value}
                </option>
            ))}
        </select>
    );
};

export default MySelect;
