// src/styles/GlobalStyles.ts
import styled, { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Arial', sans-serif;
  }
`;

export default GlobalStyles;



// Define the custom props interface
interface IconButtonProps {
  paddingRight?: string;
  disabled?: boolean; // Add disabled prop
}

// Styled component for the button with click/hover effects
export const IconButton = styled.button<IconButtonProps>`
  background: none;
  border: none;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')}; /* Disable cursor */
  padding-right: ${(props) => props.paddingRight || '10px'};
  display: flex;
  align-items: center;
  transition: transform 0.1s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.1s ease-in-out;

  /* Default background (transparent) */
  background-color: ${(props) => (props.disabled ? 'rgba(200, 200, 200, 0.5)' : 'transparent')}; /* Disable background color */

  &:hover {
    transform: ${(props) => (props.disabled ? 'none' : 'scale(1.1)')}; /* No transform on hover if disabled */
    box-shadow: ${(props) => (props.disabled ? 'none' : '0 4px 8px rgba(0, 0, 0, 0.2)')}; /* No shadow if disabled */
    background-color: ${(props) =>
      props.disabled ? 'rgba(200, 200, 200, 0.5)' : 'rgba(0, 123, 255, 0.1)'}; /* No hover color if disabled */
  }

  &:active {
    transform: ${(props) => (props.disabled ? 'none' : 'scale(0.95)')}; /* No transform on click if disabled */
    box-shadow: ${(props) => (props.disabled ? 'none' : '0 2px 4px rgba(0, 0, 0, 0.2)')}; /* No shadow if disabled */
    background-color: ${(props) =>
      props.disabled ? 'rgba(200, 200, 200, 0.5)' : 'rgba(0, 123, 255, 0.2)'}; /* No active color if disabled */
  }

  &:focus {
    outline: none; /* Remove the default focus outline */
    box-shadow: ${(props) =>
      props.disabled ? 'none' : '0 0 0 3px rgba(0, 123, 255, 0.5)'}; /* No focus shadow if disabled */
  }
`;


// Styled component for the icons
export const Icon = styled.span`
  width: 20px;
  height: 20px;
`


export const Spinner = styled.div`
  border: 2px solid #f3f3f3; /* Light grey */
  border-top: 2px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;
