import React, { useState, useEffect } from 'react';
import { Routes, Route, Link, useNavigate, useLocation } from 'react-router-dom';
import { fetchUserInformation, logoutUser } from '../features/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../app/store';
import styled from 'styled-components';
import { selectPage } from '../features/pageSlice';
import ItemsPage from './items_page';
import BoxesPage from './boxes_page';
import AddUpdateBox from './manage_box_page';
import AddUpdateItem from './manage_item_page';
import { FaBox, FaTags, FaSignOutAlt, FaFlag } from 'react-icons/fa'; // Importing icons
import { IMAGE_BASE_URL, IMAGE_ENDPOINT } from '../api/endpoints';
import BannersPage from './banners_page';
import AddUpdateBanner from './manage_banner_page';

// Styled components
const DashboardContainer = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const SideMenu = styled.div<{ isVisible: boolean }>`
  width: 320px;
  background-color: #f8f8f8;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  flex-shrink: 0; /* Prevent shrinking */
  
  @media (max-width: 1024px) {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    z-index: 1;
  }
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none; /* Remove underline */
  margin-bottom: 20px;
  color: #333; /* Dark text color */
  
  img {
    width: 60px; /* Increase logo size */
    height: 60px;
    border-radius: 50%;
    margin-right: 15px; /* Increase spacing */
  }

  h1 {
    font-size: 24px; /* Larger font size */
    font-weight: 700;
    margin: 0;
    text-transform: uppercase; /* Uppercase text */
    letter-spacing: 1px; /* Letter spacing for elegance */
  }
`;

const ProfileSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
`;

const ProfilePicture = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
`;

const ProfileName = styled.div`
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
`;

const ProfileEmail = styled.div`
  font-size: 14px;
  color: #555;
  text-align: center;
`;

const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: 15px 0;
    display: flex;
    align-items: center;

    a, button {
      text-decoration: none;
      color: #333;
      font-size: 16px;
      display: flex;
      align-items: center;
      transition: color 0.3s;
      margin-left: 10px;
    }

    a:hover, button:hover {
      color: #007bff;
    }

    button {
      background: none;
      border: none;
      cursor: pointer;
    }
  }
`;

const Icon = styled.div`
  font-size: 20px;
  color: #007bff;
`;

const ContentArea = styled.div<{ isSideMenuVisible: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0;
  padding: 15px; /* Adjusted padding to 15px on all sides */
  border-radius: 10px; /* Adding rounded corners */
  overflow: auto; /* Enable scrolling if content exceeds height */
  transition: margin-left 0.3s;

  @media (max-width: 1024px) {
    margin-left: 0;
    width: calc(100% - 30px); /* Adjusted for padding */
  }

  @media (min-width: 1024px) {
    width: calc(100% - 320px - 30px); /* Adjusted for side menu and padding */
  }
`;

const Heading = styled.div`
  height: 100px; /* Increased height */
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Align items to the left */
  border-bottom: 2px solid #e0e0e0; /* Subtle border */
  background-color: #ffffff; /* Clean background */
  padding: 0 20px; /* Padding on left and right */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  position: relative;
  width: 100%; /* Ensure full width */

  /* Align items to the left */
  & > * {
    margin: 0;
  }

  h2 {
    font-size: 24px; /* Adjust font size for better appearance */
    font-weight: 300; /* Light font weight */
    color: #555555; /* Softer color */
    letter-spacing: 0.5px; /* Slight letter spacing */
    text-align: left; /* Align text to the left */
    margin-left: 40px; /* Space between button and text */
  }

  @media (max-width: 1024px) {
    h2 {
      font-size: 20px; /* Adjust font size for smaller screens */
    }
  }
`;

const MenuButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  color: #007bff; /* Highlight color for button */
  cursor: pointer;
  transition: color 0.3s;
  
  &:hover {
    color: #0056b3; /* Darker color on hover */
  }

  @media (max-width: 1024px) {
    display: block;
  }
`;


const CloseButton = styled.button`
  display: none;
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;

  @media (max-width: 1024px) {
    display: block;
  }
`;

const MainContent = styled.div`
  flex-grow: 1;
  padding: 20px;
  background-color: #f0f0f0;
`;

const DashboardPage: React.FC = () => {
  const [isSideMenuVisible, setSideMenuVisible] = useState<boolean>(true);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const selectedPage = useSelector((state: RootState) => state.page.selectedPage);
  const user = useSelector((state: RootState) => state.app.user);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dob, setDob] = useState('');
  const [gender, setGender] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');

  // Extract user picture URL
  let pic = "https://d3dvavv1qailhf.cloudfront.net/avatars/Female_2_s.png";
  if (user?.picture && user.picture.trim().length > 1) {
    pic = `${IMAGE_BASE_URL}${IMAGE_ENDPOINT}${user.picture}`;
  }

  const handleLogout = () => {
    dispatch(logoutUser());
    navigate('/login');
  };

  const toggleSideMenu = () => {
    setSideMenuVisible(!isSideMenuVisible);
  };

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name || '');
      setLastName(user.last_name || '');
      setDob(user.dob || '');
      setGender(user.gender || '');
      setPhone(user.phone || '');
      setEmail(user.email || '');
    }
  }, [user]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024) {
        setSideMenuVisible(true);
      } else {
        setSideMenuVisible(false);
      }
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith("/dashboard/items")) {
      dispatch(selectPage("items"));
    } else if (location.pathname.startsWith("/dashboard/boxes")) {
      dispatch(selectPage("boxes"));
    } else if (location.pathname.startsWith("/dashboard/banners")) {
      dispatch(selectPage("banners"));
    } else {
      dispatch(selectPage("items"));
    }
  }, [location.pathname, dispatch]);

  useEffect(() => {
    dispatch(fetchUserInformation());
  }, [dispatch]);

  const handleMenuItemClick = (page: string) => {
    if (window.innerWidth <= 1024) {
      toggleSideMenu();
    }
    dispatch(selectPage(page));
  };

  const getDashboardTitle = () => {
    switch (selectedPage) {
      case 'items':
        return 'Items';
      case 'boxes':
        return 'Boxes';
      case 'banners':
        return 'Banners';
      default:
        return 'Items';
    }
  };

  return (
    <DashboardContainer>
      <SideMenu isVisible={isSideMenuVisible}>
        <CloseButton onClick={toggleSideMenu}>×</CloseButton>

        {/* Logo Section */}
        <LogoLink to="/">
          <img src="/logo192.png" alt="BoxChaos Logo" />
          <h1>BoxChaos</h1>
        </LogoLink>

        {/* Profile Section */}
        <ProfileSection>
          <ProfilePicture src={pic} alt="User Profile" />
          <ProfileName>{firstName} {lastName}</ProfileName>
          <ProfileEmail>{email}</ProfileEmail>
        </ProfileSection>

        {/* Navigation Links */}
        <MenuList>
          <li>
            <Icon><FaBox /></Icon>
            <Link to="items" onClick={() => handleMenuItemClick('items')}>Items</Link>
          </li>
          <li>
            <Icon><FaBox /></Icon>
            <Link to="boxes" onClick={() => handleMenuItemClick('boxes')}>Boxes</Link>
          </li>
          <li>
            <Icon><FaFlag /></Icon>
            <Link to="banners" onClick={() => handleMenuItemClick('banners')}>Banners</Link>
          </li>
          <li>
            <Icon><FaSignOutAlt /></Icon>
            <button onClick={handleLogout}>Logout</button>
          </li>
        </MenuList>
      </SideMenu>

      <ContentArea isSideMenuVisible={isSideMenuVisible}>
        <Heading>
          <MenuButton onClick={toggleSideMenu}>☰</MenuButton>
          <h2>{getDashboardTitle()}</h2>
        </Heading>

        <MainContent>

          {(selectedPage === "items" || selectedPage === "home") && (
            <Routes>
              <Route path="/items/" element={<ItemsPage />} />
              <Route path="/items/details" element={<AddUpdateItem />} />
              <Route path="/items/details/:itemId" element={<AddUpdateItem />} />
            </Routes>
          )}
          {selectedPage === "boxes" && (
            <Routes>
              <Route path="/boxes/" element={<BoxesPage />} />
              <Route path="/boxes/details" element={<AddUpdateBox />} />
              <Route path="/boxes/details/:boxId" element={<AddUpdateBox />} />
            </Routes>
          )}
          {selectedPage === "banners" && (
            <Routes>
              <Route path="/banners/" element={<BannersPage />} />
              <Route path="/banners/details" element={<AddUpdateBanner />} />
              <Route path="/banners/details/:bannerId" element={<AddUpdateBanner />} />
            </Routes>
          )}

        </MainContent>
      </ContentArea>
    </DashboardContainer>
  );
};

export default DashboardPage;
