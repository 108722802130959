// src/Api/endpoints.ts

// Define the base URLs for the API
export const BASE_URL_WITHOUT_RESTRICTIONS = "https://demo1-api.boxchaos.com/api";
export const BASE_URL = "https://demo1-api.boxchaos.com/aapi";
export const BASE_URL_NO_AAPI = "https://demo1-api.boxchaos.com";
export const BASE_URL_IMAGE = "https://demo1-api.boxchaos.com/file/";
export const IMAGE_BASE_URL = "https://demo1-api.boxchaos.com";
export const IMAGE_ENDPOINT = "/file/";
// Export all endpoints for easy reference and maintainability
export const ApiEndpoints = {
  ME: "/me",
  ITEMS: "item",
  BOXES: "box",
  FILE: "file/",
  COMPUTE_BOX: "box/compute",
  BOX_PREVIEW: "box/preview",
  LOGIN: "/login",
  BOXES_COUNT_FOR_ITEM: "/boxes_using_item",
};
