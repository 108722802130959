import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate } from "react-router-dom";
import {getUserInformationWithoutRestrictions, hasAdminRole, login} from "../features/appSlice"
import { FaEye, FaEyeSlash } from "react-icons/fa"; // Import eye icons
import styled from "styled-components";
import { AppDispatch } from "../app/store";

// Styled-components for styling the login page
const LoginContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-image: url('https://cdn.pixabay.com/photo/2017/08/30/01/05/milky-way-2695569_1280.jpg');
  background-size: cover;
  background-position: center;
`;

const LoginBox = styled.div`
  background-color: rgba(0, 0, 0, 0.6);
  padding: 2rem;
  border-radius: 8px;
  max-width: 400px;
  width: 100%;
  text-align: center;
  color: #ffffff;
`;

const Title = styled.h1`
  margin-bottom: 1.5rem;
  font-size: 2rem;
  font-weight: bold;
`;

const Input = styled.input`
  width: 90%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  color: #333;

  
`;

const PasswordContainer = styled.div`
  position: relative;

`;

const PasswordInput = styled(Input)`
  padding-right: -2.5rem; // Adjust padding to accommodate the eye icon
  width: 90%;
`;

const ToggleButton = styled.button`
  position: absolute;
  right: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
`;

const CaptchaContainer = styled.div`
  margin-top: 1rem;
    margin-bottom: 1rem;

  display: flex;
  justify-content: center;
`;

const LoginButton = styled.button`
  width: 100%;
  padding: 0.75rem;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }

  &:disabled {
    background-color: #7a7a7a;
    cursor: not-allowed;
  }
`;

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isHuman, setIsHuman] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false); // State for password visibility

  const dispatch: AppDispatch = useDispatch(); // Use AppDispatch
  const navigate = useNavigate();
  const { isLoading, error, token } = useSelector((state: any) => state.app); // Adjust based on your state slice

  useEffect(() => {
    if (token) {
      // If a token is present, the login was successful
      toast.success("Login successful!");
      // localStorage.setItem("token", token);
      window.location.href = "/";
      // checkAuth();
      checkUser();
    }
  }, [token]); // Only re-run the effect if `token` changes

  const checkUser = async () => {
    const user = await getUserInformationWithoutRestrictions();
    const isAdmin = hasAdminRole(user);
    console.log('admin role is => ' + isAdmin);

    if (isAdmin) {
      console.log('admin role is => ' + isAdmin);
      navigate("/");
    } else {
      localStorage.removeItem("token");
      localStorage.clear();
      toast(`This user ${user.email} does not have admin rights.`, {
        autoClose: 3000, // Set the duration in milliseconds (3 seconds in this example)
        onClose: () => {
          console.log("Toast dismissed!");
        },
      });
    }
  }

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleLogin = () => {
    if (!validateEmail(email)) {
      toast.error("Please enter a valid email address.");
      return;
    }

    if (password.trim() === "") {
      toast.error("Password cannot be empty.");
      return;
    }

    // if (!isHuman) {
    //   toast.error("Please complete the CAPTCHA to confirm you're human.");
    //   return;
    // }

        // Dispatch the login action
        dispatch(login({ email, password }))
        .unwrap()
        .then(() => {
          // Handle success, e.g., navigate to another page
          toast.success("Login successful!");
        })
        .catch((error: any) => {
          // Handle error if needed
          console.error("Login error:", error);
        });
    
    // Dispatch the login action
    dispatch(login({email: email, password:password }));
  };

  const onCaptchaChange = (value: any) => {
    setIsHuman(!!value);
  };

  // Handle potential login errors
  useEffect(() => {
    if (error) {
      // toast.error(`Login failed: `);

      toast.error(`Login failed: ${error}`);
    }
  }, [error]);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <LoginContainer>
      <LoginBox>
        <Title>Admin Panel</Title>
        <div>
          <Input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <PasswordContainer>
            <PasswordInput
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <ToggleButton type="button" onClick={togglePasswordVisibility}>
              {passwordVisible ? <FaEyeSlash /> : <FaEye />}
            </ToggleButton>
          </PasswordContainer>
          <CaptchaContainer>
            <ReCAPTCHA
              sitekey="6LeIpiUqAAAAAKK5YvPiPfgwUHy5JKDPnVZ64sLV"
              onChange={onCaptchaChange}
            />
          </CaptchaContainer>
        </div>
        <LoginButton onClick={handleLogin} disabled={isLoading}>
          {isLoading ? "Logging in..." : "Login"}
        </LoginButton>
      </LoginBox>
    </LoginContainer>
  );
}

export default Login;
