import React, { ReactNode } from 'react';
import styled from 'styled-components';

// Define the types for the component props
interface SubtitleProps {
  styleClass?: string;
  children: ReactNode;
}

// Styled component for the subtitle
const StyledSubtitle = styled.div<{ styleClass?: string }>`
  font-size: 1.25rem; // Equivalent to 'text-xl' in Tailwind CSS
  font-weight: 600; // Equivalent to 'font-semibold' in Tailwind CSS
  ${(props) => props.styleClass && props.styleClass} // Applying any additional styles passed through styleClass
`;

// The Subtitle component using TypeScript and styled-components
const Subtitle: React.FC<SubtitleProps> = ({ styleClass, children }) => {
  return <StyledSubtitle styleClass={styleClass}>{children}</StyledSubtitle>;
};

export default Subtitle;
