import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { createItem, updateItem, resetCreateItemStatus, resetUpdateItemStatus } from "../features/itemsSlice";
import { uploadFile } from "../features/appSlice";

import { ToastContainer, toast } from 'react-toastify';
import { ApiEndpoints, BASE_URL_NO_AAPI } from "../api/endpoints";
import { RootState, AppDispatch } from '../app/store';
import useConfirmation from '../components/confirm_modal';
import ErrorText from "../components/error_text";
import BackButton from "../components/back_button";
import { useNavigate } from "react-router-dom";
import { DynamicBoxesForAll } from "./item_boxes";
import { Button, FlexRow, FlexRowRight } from "../styles/manage_box_page_styles";
import { Spinner } from "../styles/GlobalStyles";
import { maxFileSize } from "../utils/utils";
import { api } from "../features/appSlice";

const Container = styled.div`
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  color: #4a5568;
`;

const Section = styled.section`
  margin-top: 1rem;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

export const Label = styled.label`
  margin-right: 8px;
`;

const Input = styled.input<{ isValid?: boolean }>`
  width: 300px;
  padding: 0.25rem;
  border: 1px solid ${({ isValid }) => (isValid ? '#cbd5e0' : '#f56565')};
  outline: none;
`;

const TextArea = styled.textarea<{ isValid?: boolean }>`
  width: 100%;
  padding: 0.25rem;
  border: 1px solid ${({ isValid }) => (isValid ? '#cbd5e0' : '#f56565')};
  outline: none;
`;

const FileInput = styled.input`
  width: 300px;
  height: 3rem;
  padding: 0.5rem;
  border: 1px solid #cbd5e0;
  background-color: white;
  color: #2d3748;
  outline: none;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 0.375rem;
`;

const ImagePreview = styled.div`
  width: 200px;
  height: 200px;
  overflow: hidden;
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
`;

interface ButtonProps {
    cancel?: boolean;
}


const AddUpdateItem: React.FC = () => {
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const { confirm, ConfirmationModal } = useConfirmation();

    const { createItemStatus, updateItemStatus, error, selectedItem, isCreatingOrUpdating } = useSelector((state: RootState) => state.items);

    const boxesForSelectedItem = useSelector((state: RootState) => {
        return state.items.boxesForSelectedItem ?? [];
    });

    const [errorMessage, setErrorMessage] = useState("");
    const [showToast, setShowToast] = useState(false);
    const [name, setName] = useState(selectedItem?.name || "");
    const [tagsString, setTagsString] = useState("");
    const [price, setPrice] = useState(selectedItem?.price || 0);
    const [initialOrSuggestedPrice, setSuggestedPrice] = useState(selectedItem?.price || 0);

    const [initialPrice] = useState(selectedItem?.price || 0);
    const [description, setDescription] = useState(selectedItem?.description || "");
    const [pictureUrlName, setPictureUrlName] = useState(selectedItem?.picture || "no-picture-really");
    const [selectedImage, setSelectedImage] = useState<string | null>(null);
    const [pictureFile, setPictureFile] = useState<File | null>(null);
  
    const [isValidPrice, setIsValidPrice] = useState(true);
    const [isValidName, setIsValidName] = useState(true);
    const [isValidTags, setIsValidTags] = useState(true);
    const [isValidDesc, setIsValidDesc] = useState(true);

    const [columns, setColumns] = useState(2);
    const [refreshKey, setRefreshKey] = useState(0);  // Add refreshKey state


    useEffect(() => {
        if (showToast && errorMessage) {
            // Show the toast
            toast.error(errorMessage);
            // Reset showToast to prevent repeated toasts
            setShowToast(false);
        }
    }, [showToast, errorMessage]);

    const triggerError = (message: string) => {
        setErrorMessage(message);
        setShowToast(true);
    };

    useEffect(() => {
        const handleResize = () => {
            const windowWidth = window.innerWidth;
            if (windowWidth < 600) {
                setColumns(2);
            } else if (windowWidth < 768) {
                setColumns(3);
            } else if (windowWidth < 1024) {
                setColumns(4);
            } else {
                setColumns(6);
            }
        };

        window.addEventListener("resize", handleResize);
        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const validatePrice = (str: string) => {
        const regex = /^\d+(\.\d{1,2})?$/;
        if (regex.test(str)) {
            const number = parseFloat(str);
            return number > 0;
        }
        return false;
    };

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (value.trim().length === 0) {
            value = initialPrice.toString();
        }
        setPrice(convertStringToNumber(value));
        setIsValidPrice(validatePrice(value));
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setName(value);
        setIsValidName(value.trim().length !== 0);
    };

    const handleTagsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        setTagsString(value);
        setIsValidTags(value.trim().length !== 0);
    };

    const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value;
        setDescription(value);
        setIsValidDesc(value.trim().length !== 0);
    };

    useEffect(() => {
        const isImage = /\.(gif|jpe?g|tiff?|png|webp|bmp)$/i.test(pictureUrlName);

        if (isImage) {
            const pic = `${BASE_URL_NO_AAPI}/${ApiEndpoints.FILE}${pictureUrlName}`;
            setSelectedImage(pic);
        }
    }, [pictureUrlName]);

    useEffect(() => {
        if (selectedItem?.tags) {
            // Since tags is already a string[], we can use it directly
            setTagsString(selectedItem?.tags.join(", "));
        }
    }, [selectedItem?.tags]);



    useEffect(() => {
        if (createItemStatus === "succeeded") {
            toast("New Item Created!", {
                autoClose: 3000,
                onClose: () => { },
            });
            dispatch(resetCreateItemStatus());
        } else if (createItemStatus === "failed") {


            toast(`Error creating item: ${error}`, {
                autoClose: 3000,
                onClose: () => { },
            });

            dispatch(resetCreateItemStatus());
        }
    }, [createItemStatus, error, dispatch]);

    useEffect(() => {
        if (updateItemStatus === "succeeded") {
            toast("Updated Item!", {
                autoClose: 3000,
                onClose: () => { },
            });

            dispatch(resetUpdateItemStatus());
            setRefreshKey(prev => prev + 1);  // Update refreshKey to force refresh

        } else if (updateItemStatus === "failed") {
            toast(`Error updating item: ${error}`, {
                autoClose: 3000,
                onClose: () => { },
            });

            dispatch(resetUpdateItemStatus());
        }
    }, [updateItemStatus, error, dispatch]);

    const convertStringToNumber = (str: string) => {
        const num = Number(str);
        if (!isNaN(num) && /^\d+$/.test(str)) {
            return num;
        } else {
            return -1;
        }
    };

    const cancelAction = () => {
        navigate(-1);
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
    
        if (file) {
          if (file.type === "image/jpeg" || file.type === "image/png") {
            if (file.size <= maxFileSize) {
              setSelectedImage(URL.createObjectURL(file));
              setPictureFile(file);
              setErrorMessage(""); // Clear previous errors
            } else {
                triggerError("File too large to be uploaded (max 5MB)");
              event.target.value = ""; // Clear file input
            }
          } else {
            triggerError("Please select a valid image file (JPEG or PNG).");
            event.target.value = ""; // Clear file input
          }
        }
      };

    const createOrUpdateAction = async () => {
        const validPatternForPositiveNumber = /^\d+(\.\d+)?$/;
        // let priceAfterConversion = convertStringToNumber(priceString.toString());
        // setPrice(priceAfterConversion);
        setIsValidName(name.trim().length !== 0);
        setIsValidTags(tagsString.trim().length !== 0);
        setIsValidPrice(validatePrice(price.toString()));
        setIsValidDesc(description.trim().length !== 0);

        if (name.trim() === "") return triggerError("Name is required!");
        else if (tagsString === "") return triggerError("Provide some comma separated tags");
        else if (price === -1) return triggerError("Price is not valid!");
        else if (price === 0) return triggerError("Price is required!");
        else if (!validPatternForPositiveNumber.test(price.toString())) return triggerError("Price is not correct");
        else if (description === "") return triggerError("Description is required!");
        else {
            setErrorMessage("");

            const tagsArray = tagsString.split(",").map((tag) => tag.trim()).filter((tag) => tag.length > 0);

            if (selectedItem && price !== initialPrice && boxesForSelectedItem.length > 0) {
                const isConfirmed = await confirm(`Updating this item will affect ${boxesForSelectedItem.length} boxes. Do you want to proceed?`);
                if (!isConfirmed) return;
            }

            const [uploadFileName, errorMessage] = await uploadFile(pictureFile);

            if(errorMessage && errorMessage !== "No file selected"){
                triggerError(errorMessage);
                return;
            }

            setErrorMessage("");

            let pictureNameForUpload = uploadFileName.length > 0 ? uploadFileName : pictureUrlName;

            setPictureUrlName(pictureNameForUpload);
            setPictureFile(null);

            const obj = {
                name: name,
                description: description,
                picture: pictureNameForUpload,
                tags: tagsArray,
                price: price.toString(),
                stock_count: "10",
            };

            console.log(obj);

            if (selectedItem && selectedItem.id) {
                dispatch(updateItem({ id: selectedItem.id, ...obj }));
            } else {
                dispatch(createItem(obj));
            }
            
        }
    };

    return (
        <>
            <BackButton />
            <Container>
                <ToastContainer />
                <div>
                    <FlexContainer>
                        <Label>Item Name:</Label>
                        <Input
                            type="text"
                            placeholder="Item Name"
                            value={name}
                            onChange={handleNameChange}
                            isValid={isValidName}
                        />
                    </FlexContainer>

                    <FlexContainer>
                        <Label>Tags:</Label>
                        <Input
                            type="text"
                            placeholder="Tags"
                            value={tagsString}
                            onChange={handleTagsChange}
                            isValid={isValidTags}
                        />
                    </FlexContainer>

                    <FlexContainer>
                        <Label>Price:</Label>
                        <Input
                            type="text"
                            placeholder={initialOrSuggestedPrice === 0 ? '00.00' : initialOrSuggestedPrice.toString()}
                            // value={priceString.toString()}
                            onChange={handlePriceChange}
                            isValid={isValidPrice}
                        />
                    </FlexContainer>

                    <Section>
                        <Label>Description:</Label>
                        <TextArea
                            rows={4}
                            placeholder="Description"
                            value={description}
                            onChange={handleDescriptionChange}
                            isValid={isValidDesc}
                        />
                    </Section>

                    <Section>
                        <Label>Item Image:</Label>
                        <FileInput id="picture" type="file" accept="image/jpeg, image/png" onChange={handleFileChange} />
                    </Section>

                    <Section>
                        <ImagePreview>
                            {selectedImage && <Image src={selectedImage} alt="Preview" />}
                        </ImagePreview>
                    </Section>

                    {selectedItem && (
                        <Section>
                            {/* Pass refreshKey to DynamicBoxesForAll to force re-render */}
                            <DynamicBoxesForAll itemId={selectedItem.id!} columns={columns} refreshKey={refreshKey} />
                        </Section>
                    )}


                    <FlexRowRight>
                        <ErrorText>{errorMessage}</ErrorText>
                    </FlexRowRight>
                    <FlexRow>
                        <Button onClick={cancelAction}>Cancel</Button>
                        <Button
                            primary
                            onClick={createOrUpdateAction}
                            disabled={isCreatingOrUpdating}
                        >
                            {isCreatingOrUpdating && <Spinner />}
                            {isCreatingOrUpdating ? '' : (selectedItem === null ? 'Add Item' : 'Update Item')}
                        </Button>
                    </FlexRow>

                </div>

                <ConfirmationModal />
            </Container>
        </>

    );
}

export default AddUpdateItem;
