import React from 'react';
import { TiArrowBack } from 'react-icons/ti';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const StyledBackButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  border: 0;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background-color: #e2e8f0;
  color: #2d3748;
  border: 1px solid #cbd5e0;

  transition: background-color 0.3s ease, transform 0.2s ease; /* Smooth hover transition */

  &:hover {
    background-color: #cbd5e0; /* Darker grey on hover */
    transform: scale(1.05); /* Slight zoom effect */
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 0.75rem;
  }
`;

const BackButton: React.FC = () => {
  const navigate = useNavigate();

  const handleBackButtonClick = () => {
    // Navigate back to the last page
    navigate(-1);
  };

  return (
    <StyledBackButton onClick={handleBackButtonClick} type="button">
      <TiArrowBack />
      Back
    </StyledBackButton>
  );
};

export default BackButton;
