// store.ts
import { configureStore } from "@reduxjs/toolkit";
import {appReducer} from "../features/appSlice"; // Adjust import based on your file structure
import pageReducer from '../features/pageSlice';
import itemsSlice from "../features/itemsSlice";
import boxesSlice from "../features/boxSlice";
import bannersSlice from "../features/bannersSlice";

const store = configureStore({
  reducer: {
    app: appReducer,
    page: pageReducer,
    items: itemsSlice,
    boxes: boxesSlice,
    banners: bannersSlice,
  },
});

// Define AppDispatch and RootState types
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
