import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch, RootState } from "../app/store";
import { ApiEndpoints } from "../api/endpoints";
import { api } from "./appSlice";
import { Box } from "../modal/box";
import { Item } from "../modal/Item";
import { BoxComputeType, ComputeItem } from "../modal/Compute_Item";
import { Row } from "../modal/row";
import { constructBoxComputeType } from "../utils/utils";


interface BoxState {
  isLoading: boolean;
  isNeedToRefresh: boolean;
  boxes: Box[];
  currentPage: number;
  boxesPerPage: number;
  totalItems: number;
  searchString: string;
  createBoxStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  updateBoxStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  selectedBox: Box | null;
  isLoadingBoxDetails: boolean;
  getBoxDetailsByIdStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  getBoxDetailsByIdError: string | null;
  isCreatingOrUpdating: boolean;
  duplicateBoxStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  duplicateBoxError: string | null;
  isDuplicateBoxInprogress: boolean;
  duplicateBoxId: string | null;  // Track boxId being duplicated
  deleteBoxStatus: 'idle' | 'loading' | 'succeeded' | 'failed';
  deleteBoxError: string | null;
  isDeleteBoxInprogress: boolean;
  deleteBoxId: string | null;  // Track boxId being deleted
}



const initialState: BoxState = {
  isLoading: false,
  isNeedToRefresh: false,
  boxes: [],
  currentPage: 1,
  boxesPerPage: 20, // Set your desired items per page
  totalItems: 0,
  searchString: "",
  createBoxStatus: 'idle',
  updateBoxStatus: 'idle',
  error: null,
  selectedBox: null,
  getBoxDetailsByIdStatus: 'idle',
  getBoxDetailsByIdError: null,
  isLoadingBoxDetails: false,
  isCreatingOrUpdating: false,
  duplicateBoxStatus: 'idle',
  duplicateBoxError: null,
  isDuplicateBoxInprogress: false,
  duplicateBoxId: null,
  deleteBoxStatus: 'idle',
  deleteBoxError: null,
  isDeleteBoxInprogress: false,
  deleteBoxId: null,
};


export const duplicateBox = createAsyncThunk<
  void,
  { id: string; name: string }, // The box_id and name will be passed as an object
  { dispatch: AppDispatch, rejectValue: string }
>(
  "/boxes/duplicateBox",
  async ({ id, name }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post(`${ApiEndpoints.BOXES}/duplicate?box_id=${id}&name=${name}`);
      dispatch(getBoxesContent()); // Now it knows how to handle getBoxesContent
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail || "Failed to duplicate box");
      }
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const deleteBox = createAsyncThunk<
  void,
  { id: string }, // The box_id and name will be passed as an object
  { dispatch: AppDispatch, rejectValue: string }
>(
  "/boxes/deleteBox",
  async ({ id }, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.get(`${ApiEndpoints.BOXES}/delete/${id}`);
      dispatch(getBoxesContent()); // Now it knows how to handle getBoxesContent
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail || "Failed to delete box");
      }
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);


export const get1000Items = async () => {
  try {
    // Define parameters
    const offset = 0;
    const limit = 1000;

    // Construct the URL with parameters
    const apiWithParams = `${ApiEndpoints.ITEMS}?offset=${offset}&limit=${limit}`;

    const response = await api.get(apiWithParams);
    const itemsArray = Item.parseItems(response.data.list);
    return itemsArray;
  } catch (error) {
    console.error("Error fetching items:", error);
    throw error;
  }
};


// export async function boxCompute( computeItems : BoxComputeType ) : Promise<ComputeItem | null>  {
//   try {
//     let ep = `${ApiEndpoints.COMPUTE_BOX}` ;
//     const response = await api.post(ep, computeItems);
//     if(response.status == 200){
//       let cmputeItem : ComputeItem = response.data ;
//       console.log("Box Compute ComputeItem => " + cmputeItem.other_items.length);
//       return cmputeItem ;
//     }
//     return null ;

//   } catch (error) {
//     console.error("Error boxCompute() : ", error);
//     throw error;
//   }
// };


export async function boxCompute(rows: Row[]): Promise<ComputeItem | null> {
  try {
    let ep = `${ApiEndpoints.COMPUTE_BOX}`;
    const computeItems = constructBoxComputeType(rows);
    const response = await api.post(ep, computeItems);
    if (response.status == 200) {
      let cmputeItem: ComputeItem = response.data;
      console.log("Box Compute ComputeItem => " + cmputeItem.other_items.length);
      return cmputeItem;
    }
    return null;

  } catch (error) {
    console.error("Error boxCompute() : ", error);
    throw error;
  }
};

export const getBoxesContent = createAsyncThunk<
  { list: Box[]; total: number },
  void,
  { state: RootState }
>(
  "/boxes/content",
  async (_, { getState, rejectWithValue }) => {
    try {
      const boxesState = getState().boxes;

      if (!boxesState) {
        // Handle the case where the items state is not defined
        console.error("Boxes state is not defined");
        return rejectWithValue("Boxes state is not defined");
      }
      const { currentPage, boxesPerPage, searchString } = boxesState;
      const offset = (currentPage - 1) * boxesPerPage;

      const queryParams: Record<string, string | number> = {
        offset,
        limit: boxesPerPage,
      };

      if (searchString.trim().length > 0) {
        queryParams.q = searchString.trim();
      }

      const response = await api.get<{ list: Box[]; total: number }>(ApiEndpoints.BOXES, {
        params: queryParams,
      });
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);


// Define the createBox thunk with appropriate types
export const createBox = createAsyncThunk<
  Box,
  Box,
  { dispatch: AppDispatch, rejectValue: string }
>(
  "/boxes/createBox",
  async (boxData, { dispatch, rejectWithValue }) => {
    try {
      const response = await api.post<Box>(ApiEndpoints.BOXES, boxData);

      // Dispatch getBoxesContent to refresh the list of boxes
      dispatch(getBoxesContent());

      return response.data;
    } catch (error: any) {
      // Capture the error response
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

// Define the thunk with appropriate types
export const updateBox = createAsyncThunk<
  Box,
  Box,
  { dispatch: AppDispatch, rejectValue: string }
>(
  "/boxes/updateBox",
  async ({ id, ...boxData }, { dispatch, rejectWithValue }) => {
    try {
      const urlString = `${ApiEndpoints.BOXES}/${id}`;
      const response = await api.post<Box>(urlString, boxData);

      // Correctly typed dispatch
      dispatch(getBoxesContent()); // Now it knows how to handle getBoxesContent

      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail);
      }
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

// Define the getBoxDetailsById thunk
export const getBoxDetailsById = createAsyncThunk<
  Box,
  string,
  { rejectValue: string }
>(
  "/boxes/getBoxDetailsById",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get<Box>(`${ApiEndpoints.BOXES}?id=${id}`);
      return response.data;
    } catch (error: any) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data.detail || "Failed to fetch box details");
      }
      return rejectWithValue(error.message || "An error occurred");
    }
  }
);

export const boxesSlice = createSlice({
  name: "boxes",
  initialState,
  reducers: {
    setCurrentPage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
      state.isNeedToRefresh = true; // Trigger refresh when the page changes
    },
    setSearchString: (state, action: PayloadAction<string>) => {
      state.searchString = action.payload;
    },
    setSelectedBox: (state, action: PayloadAction<Box | null>) => {
      state.selectedBox = action.payload;
    },
    resetCreateBoxStatus: (state) => {
      state.createBoxStatus = 'idle';
      state.error = null;
    },
    resetUpdateBoxStatus: (state) => {
      state.updateBoxStatus = 'idle';
      state.error = null;
    },
    resetDuplicateBoxStatus: (state) => {
      state.duplicateBoxStatus = 'idle';
      state.duplicateBoxError = null;
      state.duplicateBoxId = null; // Reset the boxId being tracked
    },
    resetDeleteBoxStatus: (state) => {
      state.deleteBoxStatus = 'idle';
      state.deleteBoxError = null;
      state.deleteBoxId = null; // Reset the boxId being tracked
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getBoxesContent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getBoxesContent.fulfilled, (state, action) => {
        state.boxes = action.payload.list;
        state.totalItems = action.payload.total;
        state.isLoading = false;
      })
      .addCase(getBoxesContent.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || "Failed to fetch boxes content";
      })
      .addCase(createBox.pending, (state) => {
        state.isLoading = true;
        state.isNeedToRefresh = false;
        state.createBoxStatus = 'loading';
        state.error = null;
        state.isCreatingOrUpdating = true;
      })
      .addCase(createBox.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isNeedToRefresh = true;
        state.createBoxStatus = 'succeeded';
        state.selectedBox = action.payload;
        state.isCreatingOrUpdating = false;
      })
      .addCase(createBox.rejected, (state, action) => {
        state.isLoading = false;
        state.isNeedToRefresh = false;
        state.createBoxStatus = 'failed';
        state.error = action.payload || action.error.message || "Failed to create box";
        state.isCreatingOrUpdating = false;
      })
      .addCase(updateBox.pending, (state) => {
        state.isLoading = true;
        state.isNeedToRefresh = false;
        state.updateBoxStatus = 'loading';
        state.error = null;
        state.isCreatingOrUpdating = true;
      })
      .addCase(updateBox.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isNeedToRefresh = true;
        state.updateBoxStatus = 'succeeded';
        state.selectedBox = action.payload;
        state.isCreatingOrUpdating = false;
      })
      .addCase(updateBox.rejected, (state, action) => {
        state.isLoading = false;
        state.isNeedToRefresh = false;
        state.updateBoxStatus = 'failed';
        state.error = action.payload || action.error.message || "Failed to update box";
        state.isCreatingOrUpdating = false;
      })
      .addCase(getBoxDetailsById.pending, (state) => {
        state.isLoadingBoxDetails = true;
        state.getBoxDetailsByIdStatus = 'loading';
        state.getBoxDetailsByIdError = null;
      })
      .addCase(getBoxDetailsById.fulfilled, (state, action) => {
        state.isLoadingBoxDetails = false;
        state.getBoxDetailsByIdStatus = 'succeeded';
        state.selectedBox = action.payload;
      })
      .addCase(getBoxDetailsById.rejected, (state, action) => {
        state.isLoadingBoxDetails = false;
        state.getBoxDetailsByIdStatus = 'failed';
        state.getBoxDetailsByIdError = action.payload || action.error.message || "Failed to fetch box details";
      })
      .addCase(duplicateBox.pending, (state, action) => {
        state.duplicateBoxStatus = 'loading';
        state.duplicateBoxError = null;
        state.isDuplicateBoxInprogress = true;
        state.duplicateBoxId = action.meta.arg.id;
      })
      .addCase(duplicateBox.fulfilled, (state) => {
        state.duplicateBoxStatus = 'succeeded';
        state.isDuplicateBoxInprogress = false;
        state.isNeedToRefresh = true; // Refresh the boxes list if needed
        state.duplicateBoxId = null; // Clear boxId after failure
      })
      .addCase(duplicateBox.rejected, (state, action) => {
        state.duplicateBoxStatus = 'failed';
        state.duplicateBoxError = action.payload || action.error.message || "Failed to duplicate box";
        state.isDuplicateBoxInprogress = false;
        state.duplicateBoxId = null; // Clear boxId after failure
      })
      .addCase(deleteBox.pending, (state, action) => {
        state.deleteBoxStatus = 'loading';
        state.deleteBoxError = null;
        state.isDeleteBoxInprogress = true;
        state.deleteBoxId = action.meta.arg.id;
      })
      .addCase(deleteBox.fulfilled, (state) => {
        state.deleteBoxStatus = 'succeeded';
        state.isDeleteBoxInprogress = false;
        state.isNeedToRefresh = true; // Refresh the boxes list if needed
        state.deleteBoxId = null; // Clear boxId after failure
      })
      .addCase(deleteBox.rejected, (state, action) => {
        state.deleteBoxStatus = 'failed';
        state.deleteBoxError = action.payload || action.error.message || "Failed to duplicate box";
        state.isDeleteBoxInprogress = false;
        state.deleteBoxId = null; // Clear boxId after failure
      });
  },
});

export const {
  setCurrentPage,
  setSearchString,
  setSelectedBox,
  resetCreateBoxStatus,
  resetUpdateBoxStatus,
  resetDuplicateBoxStatus,
  resetDeleteBoxStatus,
} = boxesSlice.actions;

export default boxesSlice.reducer;

